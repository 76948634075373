import { useState, Fragment } from "react";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

import { Spinner } from "../../../../../LoadingContainer/Spinner";

import soldout from '../../../../../../assets/icons/soldout.svg';

export default function SoldOutContent({ layout, icon }) {

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getNotified = (e) => {
        e.preventDefault()
        setIsLoading(true)
        setEmailError(!emailError);
        setIsLoading(false)
    }

    const getFormControl = () => {
        return (
            <Form.Control
                className="presale_input"
                type="text"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
        )
    }

    const getButton = () => {
        return (
            <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={!email || isLoading}
                className={`icon-button icon-button--white btn-width-md ${!isLoading ? 'btn-notify' : ''} `}>
                {isLoading ? <Spinner /> : "Get Notified"}
            </Button>
        )
    }

    const getFormLayout = () => {
        if (layout === 'desktop') {
            return (
                <>
                    <div className={`get_presale_inner ${emailError ? 'input-group-error' : ''}`}>
                        <InputGroup>
                            <div className="input-wrapper">
                                {getFormControl()}
                                {getButton()}
                            </div>
                        </InputGroup>
                    </div>
                    {emailError &&
                        <div className="presale_incorrect info-text info-text-danger info-text-md">
                            <p>You are already on the waiting list, please use another email.</p>
                        </div>
                    }
                </>
            )
        } else if (layout === 'mobile') {
            return (
                <div className="mob_layout">
                    <div className={`input-container ${emailError ? 'error' : ''}`}>
                        {getFormControl()}
                    </div>
                    {emailError &&
                        <div className="info-text info-text-danger info-text-md">
                            <p>You are already on the waiting list, please use another email.</p>
                        </div>
                    }
                    {getButton()}
                </div>
            )
        }
    }

    return (
        <Fragment>
            {icon && <img className="soldout_icon" src={soldout} alt='soldout' />}
            <h5 className="presale_heading">We’re sorry, the event is sold out. 😔</h5>
            <p className="presale_info">Enter your email below to get notified in case a ticket becomes available</p>
            <Form onSubmit={getNotified}>
                {getFormLayout()}
            </Form>
        </Fragment>
    )
}