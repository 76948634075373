import React from 'react';

import Stack from 'react-bootstrap/Stack';

import { Package } from './Package';

export default function PackagesList({ orders }) {

    return (
        <Stack gap={3} as="ul">
            {orders?.map((order, idx) => (
                <Package key={idx} orderId={order?.orderId} order={order?.package} />
            ))}
        </Stack>
    );
}
