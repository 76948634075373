import useSeatmapStore from "../../../../../../../../../stores/seatmapStore";
import "./zoomLevel.scss";

export default function ZoomLevel({ zoomRef, afterZoomChange }) {
  // Zustand
  const scale = useSeatmapStore(state => state.scale);
  
  const ZOOM_ANIMATION_TIME = 200;
  const zoomIn = () => {
    zoomRef.current.zoomIn(0.5, ZOOM_ANIMATION_TIME, "easeOut");

    setTimeout(() => {
      const newScale = zoomRef.current.state.scale;
      afterZoomChange(newScale);
    }, ZOOM_ANIMATION_TIME);
  };

  const zoomOut = () => {
    zoomRef.current.zoomOut(0.5, ZOOM_ANIMATION_TIME, "easeOut");

    setTimeout(() => {
      const newScale = zoomRef.current.state.scale;
      afterZoomChange(newScale);
    }, ZOOM_ANIMATION_TIME);
  };

  const scaleCalculation = () => {
    return parseInt((scale - 1) * 11);
  };

  return (
    <div className='zoomContainer'>
      <button className='zoomButton' onClick={() => zoomOut()}>
        -
      </button>
      <div>{scaleCalculation()}%</div>
      <button className='zoomButton' onClick={() => zoomIn()}>
        +
      </button>
    </div>
  );
}
