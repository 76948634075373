import { useEffect, useState } from "react";

import { useGAUnlock } from "../../../../NewGAPurchaseWrapper/GAUnlockProvider/GAUnlockProvider";

import {
    formatCurrency,
    capitalizeString,
    getValidQuantitiesForTicketGroup,
} from "../../../../../../utilities/helpers";

import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";

import { QuickpickBadge } from "../../../../NewSeatedPurchaseWrapper/NewSeatedPurchaseContainer/TicketSelectionPanel/ListingsContainer/TicketGroup/TicketGroup";
import DetailsButton from "../../../DetailsButton/DetailsButton";
import { AddSubtractQuantity } from "../../../AddSubtractQuantity";
import { Spinner } from "../../../../../LoadingContainer/Spinner";

import "./GATickets.scss";

export default function GATickets({
    quickpicks,
    selectQuickPick,
    checkingAvailability,
    event,
}) {
    const { openUnlockModal } = useGAUnlock();
    const [openIndex, setOpenIndex] = useState(0);
    const [quantity, setQuantity] = useState(1);
    
    const getPriceForConnectedOffer = (PLName, am_pricing_objects) => {
        const correctPricingObject = am_pricing_objects.find(
            (PO) => PO.name === PLName
        );

        return correctPricingObject.totalDue;
    };

    // First, create a flat list of all tickets
    let allTickets = quickpicks.reduce((acc, quickpick) => {
        if (!quickpick.GA) {
            return acc;
        }

        // Add main offer
        acc.push({
            type: "main",
            quickpick: quickpick,
            isLocked: quickpick.offer.accessCode && !quickpick?.offer?.unlocked,
        });

        // Add any discount offers
        if (quickpick.offer?.connected_offers?.length) {
            quickpick.offer.connected_offers.forEach((discountOffer) => {
                acc.push({
                    type: "connected-offer",
                    quickpick: {
                        ...quickpick,
                        price: getPriceForConnectedOffer(
                            quickpick.PLName,
                            discountOffer.am_pricing_objects
                        ),
                        offer: {
                            ...discountOffer,
                        },
                    },
                });
            });
        }

        return acc;
    }, []);

    // Order by offer price
    allTickets = allTickets.sort((a, b) => {
        if (a?.quickpick?.price < b?.quickpick?.price) {
            return -1;
        }
        if (a?.quickpick?.price > b?.quickpick?.price) {
            return 1;
        }
        return 0;
    });

    return (
        <ul className='vjx-ga-ul'>
            {allTickets.map((ticket, index) => {
                if (ticket.isLocked) {
                    return (
                        <LockedTicket
                            key={`ticket-${index}`}
                            index={index}
                            open={openIndex === index}
                            quickpick={ticket.quickpick}
                            openUnlockModal={openUnlockModal}
                        />
                    );
                }

                return (
                    <GATicket
                        key={`ticket-${index}`}
                        index={index}
                        open={openIndex === index}
                        setOpen={() => setOpenIndex(index)}
                        quantity={quantity}
                        setQuantity={setQuantity}
                        quickpick={ticket.quickpick}
                        selectQuickPick={selectQuickPick}
                        checkingAvailability={checkingAvailability}
                        eventTicketLimit={event?.eventTicketLimit}
                    />
                );
            })}
        </ul>
    );
}

function LockedTicket({ index, open, quickpick, openUnlockModal }) {
    const offer = quickpick.offer;
    return (
        <li
            aria-controls={`ticket-collapse-text-${index}`}
            aria-expanded={open}
            className={`vjx-ga-ticket ${open ? "--active" : ""}`}
        >
            <Stack direction='horizontal' className='justify-content-between'>
                <div className='vjx-locked-left-col'>
                    <span className='vjx-ticket-type'>{offer?.name}</span>
                    <QuickpickBadge
                        isListing={false} // Val: change this to true if pick is listing
                        listing={undefined}
                        inventoryType={offer?.inventoryType}
                        offerName={"Locked Offer"}
                    />
                </div>
                <Button
                    variant='primary'
                    size='lg'
                    className='d-flex justify-content-center m-0'
                    onClick={() => openUnlockModal(offer)}
                >
                    Unlock
                </Button>
            </Stack>
        </li>
    );
}

function GATicket({
    index,
    open,
    setOpen,
    quantity,
    setQuantity,
    quickpick,
    selectQuickPick,
    checkingAvailability,
    eventTicketLimit,
}) {
    const [detailsOpen, setDetailsOpen] = useState(false);
    const offer = quickpick.offer;
    const validQuantities = getValidQuantitiesForTicketGroup(
        offer,
        quickpick.availableCount,
        eventTicketLimit
    );

    useEffect(() => {
        if (!open) {
            setDetailsOpen(false);
        }
    }, [open, setDetailsOpen]);

    const handleDetailsButtonClick = (e) => {
        e.stopPropagation(); // Prevent the click from bubbling up to the parent button
        setDetailsOpen(!detailsOpen);
    };

    const increment = () => {
        // If no valid quantities exist, return
        if (validQuantities.length === 0) {
            return;
        }

        // Find the next valid quantity
        const newQuantity = validQuantities.find((q) => q > quantity) || null;

        if (newQuantity !== null) {
            setQuantity(newQuantity);
        }
    };

    const decrement = () => {
        // If no valid quantities exist or current quantity is 0, return
        if (validQuantities.length === 0 || quantity === 0) {
            return;
        }

        // If we're at the minimum valid quantity, go to 0
        if (quantity === validQuantities[0]) {
            setQuantity(0);
            return;
        }

        // Find the highest valid quantity that's less than current
        const newQuantity = validQuantities.filter((q) => q < quantity).pop() || 0;

        if (newQuantity !== null) {
            setQuantity(newQuantity);
        }
    };

    console.log("quickpick: ", quickpick)
    return (
        <li
            onClick={setOpen}
            aria-controls={`ticket-collapse-text-${index}`}
            aria-expanded={open}
            className={`vjx-ga-ticket ${open ? "--active" : ""}`}
        >
            <Stack>
                <div className='vjx-ga-txt-col'>
                    <div className='d-flex d-flex-column'>
                        <span className='vjx-ticket-type'>{offer?.name}</span>
                        <span className='vjx-ticket-taxes'>{capitalizeString(quickpick?.sectionName)}</span>
                    </div>
                    <div className='d-flex d-flex-column'>
                        <span className='vjx-ticket-price'>
                            {formatCurrency(quickpick?.price)}
                        </span>
                        <span className='vjx-ticket-taxes'>Incl. Taxes & Fees</span>
                    </div>
                </div>
            </Stack>
            <Collapse in={open}>
                <div id={`ticket-collapse-text-${index}`}>
                    <div className='vjx-collapse-one'>
                        {offer?.description ? (
                            <DetailsButton
                                text={"Ticket details"}
                                onClick={handleDetailsButtonClick}
                                aria-controls={`ticket-details-text-${index}`}
                                aria-expanded={detailsOpen}
                            />
                        ) : (
                            <span className='caption caption-bold text-muted'>
                                Ticket limit: {validQuantities[validQuantities.length - 1]}
                            </span>
                        )}
                        <div className='vjx-quantity-button'>
                            <AddSubtractQuantity
                                whiteBG={true}
                                quantity={quantity}
                                increment={increment}
                                decrement={decrement}
                            />
                            <Button
                                onClick={(e) => selectQuickPick(quickpick, quantity)}
                                disabled={checkingAvailability}
                                variant='primary'
                                size='lg'
                                style={{ width: "128px", height: "48px" }}
                                className='d-flex justify-content-center m-0'
                            >
                                {checkingAvailability ? <Spinner /> : "Checkout"}
                            </Button>
                        </div>
                    </div>
                </div>
            </Collapse>
            <Collapse in={detailsOpen}>
                <div id={`ticket-details-text-${index}`}>
                    <div className='vjx-collapse-two'>
                        <pre>{offer?.description}</pre>
                    </div>
                </div>
            </Collapse>
        </li>
    );
}
