import React, {
  createContext,
  useContext,
  useRef,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { dropUserCart } from "../../../../../utilities/api";

const TimeoutContext = createContext(undefined);

export const useTimeout = () => {
  const context = useContext(TimeoutContext);
  if (!context) {
    throw new Error("useTimeout must be used within a TimeoutProvider");
  }
  return context;
};

export const TimeoutProvider = ({ children }) => {
  const timerRef = useRef(null);
  const timeoutRef = useRef(null);
  const navigate = useNavigate();
  const [eventUUID, setEventUUID] = useState(undefined);
  const [isTimeout, setIsTimeout] = useState(false);
  const [isPurchasing, setIsPurchasing] = useState(false);

  const startTimer = (remainingTime, eventUUID = undefined) => {
    if (eventUUID) {
      setEventUUID(eventUUID);
    }

    // Clear any existing timer
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Set the timeout
    timeoutRef.current = remainingTime;

    if (remainingTime <= 0) {
      setIsTimeout(true);
      return;
    }

    timerRef.current = setTimeout(() => {
      setIsTimeout(true);
    }, remainingTime);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const exitCheckout = () => {
    // First release user cart
    let data = {
      eventUUID: eventUUID,
    };

    // no need to wait...
    dropUserCart(data);
    // then navigate back
    navigate(-1);
  };

  // Timeout is only shown IF not purchasing + timeout
  const TimeoutDialog = () => (
    <Modal show={isTimeout && !isPurchasing} backdrop='static' keyboard={false} centered>
      <Modal.Header>
        <Modal.Title>Session Expired</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Your session has expired. The items in your cart have been released.
      </Modal.Body>
      <Modal.Footer>
        <Button size='lg' variant='primary' onClick={exitCheckout}>
          Exit
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <TimeoutContext.Provider
      value={{
        startTimer,
        isPurchasing,
        setIsPurchasing
      }}
    >
      {children}
      <TimeoutDialog />
    </TimeoutContext.Provider>
  );
};
