import "./sideBottomModal.scss";
import SheetBottom from "../../../../components/SheetBottom/SheetBottom";
import TicketDetails from "../../../../components/TicketDetails/TicketDetails";
import useSeatmapStore from "../../../../../../stores/seatmapStore";
import { useCheckout } from "../../CheckoutProvider";
import useFiltersStore from "../../../../../../stores/filtersStore";

export default function SideBottomModal({
  dialogRef,
  handleClose,
  selectedFromQuickpicks,
  changeQuickpickQuantity,
}) {
  // Zustand
  const getTicketImage = useSeatmapStore(state => state.getTicketImage);
  const eventTicketLimit = useFiltersStore(state => state.eventTicketLimit);
  const { checkout, checkingAvailability } = useCheckout();

  const handleClick = (event) => {
    if (event.target === dialogRef.current) {
      handleClose();
    }
  };

  // Check if selectedFromQuickpicks and its properties exist
  const hasValidSelection =
    selectedFromQuickpicks &&
    (selectedFromQuickpicks.offer || selectedFromQuickpicks.listing);

  const totalPrice = hasValidSelection
    ? (selectedFromQuickpicks.offer
        ? selectedFromQuickpicks.price
        : selectedFromQuickpicks.listing.askingPrice) *
      selectedFromQuickpicks?.quantity
    : 0;

  return (
    <dialog
      id='side-bottom-modal'
      ref={dialogRef}
      onClick={handleClick}
      className='vjx-sbmodal'
    >
      <div className='sbmodal-inner'>
        <div className='sbmodal-content'>
          {hasValidSelection ? (
            <TicketDetails
              toggleView={handleClose}
              selectedObject={selectedFromQuickpicks}
              getTicketImage={getTicketImage}
              eventTicketLimit={eventTicketLimit}
              allowTicketCountChange={true}
              changeQuickpickQuantity={changeQuickpickQuantity}
            />
          ) : (
            <p>No ticket selected or invalid selection data.</p>
          )}
        </div>
        <SheetBottom
          totalTicketsPrice={totalPrice}
          selectedTicketCount={selectedFromQuickpicks?.quantity}
          checkout={() => checkout(true, selectedFromQuickpicks)} // is quickpick
          checkingAvailability={checkingAvailability}
        />
      </div>
    </dialog>
  );
}
