import { SheetTop } from "../SheetTop";
import { SelectedTicket } from "./SelectedTicket";
import "./yourSelection.scss";

export default function YourSelection({ toggleView, canGoBack, goBack, selectedFromMap, eventTicketLimit }) {
  return (
    <div className='vjy-yb'>
      <SheetTop canGoBack={canGoBack} goBack={goBack} />
      <div className='vjy-sc'>
        {eventTicketLimit && (
          <span className='vjy-etl'>
            Event ticket limit: {eventTicketLimit}
          </span>
        )}
        {selectedFromMap &&
          selectedFromMap.map((ticketGroup, i) => {
            if (ticketGroup.GA === true) {
              return Array(ticketGroup.quantity)
                .fill()
                .map((_, j) => (
                  <SelectedTicket
                    key={`ga-${i}-${j}`}
                    ticketGroup={ticketGroup}
                    toggleView={toggleView}
                  />
                ));
            } else {
              return (
                <SelectedTicket
                  key={`seat-${i}`}
                  ticketGroup={ticketGroup}
                  toggleView={toggleView}
                />
              );
            }
          })}
      </div>
    </div>
  );
}
