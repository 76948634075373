import { useEffect, useRef, useState } from "react";

import { SwiperSlide } from "swiper/react";

import useSeatmapStore from "../../../../stores/seatmapStore";
import useFiltersStore from "../../../../stores/filtersStore";

import Button from "react-bootstrap/Button";

import { Slider } from "../../../Slider";

import chevronleft from "../../../../assets/icons/chevronleft.svg";

import "./viewSwiper.scss";

export default function ViewSwiper({
    viewingSectionId,
    smallButtons = false,
    imageHeight = 230,
    sectionNumber,
    slug
}) {
    // Zustand
    const getTicketImage = useSeatmapStore((state) => state.getTicketImage);
    const event = useFiltersStore(state => state.event);

    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    const sliderKey = `slider-${viewingSectionId}-${Math.random()}`;

    const [availableImages, setAvailableImages] = useState([]);

    const venueSlug = event?.venue?.slug ? event?.venue?.slug : slug

    useEffect(() => {
        const checkImages = async () => {
            const imageTypes = ["highlights", "seat-view"];
            const available = [];

            for (const type of imageTypes) {
                const imgUrl = getTicketImage(venueSlug, sectionNumber?.toLowerCase(), type);

                try {
                    const response = await fetch(imgUrl, { method: "HEAD" });
                    if (response.ok) {
                        available.push({
                            type,
                            url: imgUrl,
                            key: type === "highlights" ? 1 : 2,
                            alt:
                                type === "highlights"
                                    ? "Seat position for this section"
                                    : "Seat view for this ticket",
                        });
                    }
                } catch (error) {
                    console.warn(`Image check failed for ${type}:`, error);
                }
            }
            setAvailableImages(available);
        };

        checkImages();
    }, [viewingSectionId, event, getTicketImage]);

    // Make loading smoother; more elegant
    // Fallback for when no images are found/ 404
    return (
        <div className='--tslider'>
            <div className={`button-box ${smallButtons && "--smallbuttons"}`}>
                <Button
                    variant='outline-light'
                    className='btn--icon --left'
                    ref={navigationPrevRef}
                    aria-label='left arrow'
                >
                    <img src={chevronleft} alt='left arrow' />
                </Button>
                <Button
                    variant='outline-light'
                    className='btn--icon --right'
                    ref={navigationNextRef}
                    aria-label='right arrow'
                >
                    <img src={chevronleft} alt='left arrow' />
                </Button>
            </div>
            <Slider
                key={sliderKey}
                slidesPerView={1}
                navigationPrevRef={navigationPrevRef}
                navigationNextRef={navigationNextRef}
            >
                {availableImages.map((image) => (
                    <SwiperSlide key={image.key} className='swiper-lazy'>
                        <div className='vjx-swiper-img-wrap' style={{ height: `${imageHeight}px` }}>
                            <img src={image.url} alt={image.alt} />
                        </div>
                    </SwiperSlide>
                ))}
            </Slider>
        </div>
    );
}
