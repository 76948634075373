import Card from "react-bootstrap/Card";

import { FilterBar } from "./FilterBar";
import { ListingsContainer } from "./ListingsContainer";
import { FindOnMap } from "../../../components/FindOnMap";
import { LoadingContainer } from "../../../../LoadingContainer";
import { useEffect, useState } from "react";
import { getTicketGroups } from "../../../../../utilities/api";
import { useLocation } from "react-router-dom";
import useFiltersStore from "../../../../../stores/filtersStore";

export default function TicketSelectionPanel({ seatmapPreview }) {
  // Zustand
  const event = useFiltersStore(state => state.event);
  const filters = useFiltersStore((state) => state.filters);
  // Search related
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const setFilters = useFiltersStore((state) => state.setFilters);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTickets, setIsLoadingTickets] = useState(true);
  const [isMappingCreated, setIsMappingCreated] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduledTime, setScheduledTime] = useState(false);
  const [needToUnlock, setNeedToUnlock] = useState(false);
  const [quickpicks, setQuickpicks] = useState([]);
  const [soldout, setSoldout] = useState(false);

  const getQuickPicksAndListings = async (newFilters) => {
    setIsLoadingTickets(true);

    try {
      let data = {
        event,
        quantity: newFilters.quantity,
        offerIds: newFilters.selectedOfferIds,
        accessCodes: newFilters.accessCodes,
        accessible: newFilters.accessible,
        sort: newFilters.sort,
        returnLocked: false,
      };

      let res = await getTicketGroups(data);

      setIsScheduled(res.data.isScheduled);
      setScheduledTime(res.data.scheduledTime);
      setIsMappingCreated(true);
      setNeedToUnlock(res.data.needToUnlock);

      // If searching for specific offerIds, only add ticketGroups
      if (res.data.soldout) {
        setSoldout(true);
      } else {
        setQuickpicks([...res.data.ticketGroups]);
      }

    } catch (error) {
      console.error("Error fetching quickpicks:", error);
    } finally {
      setIsLoadingTickets(false);
      setIsLoading(false);
    }
  };

  const reloadQuickPicks = () => {
    setIsLoading(true);
    getQuickPicksAndListings(filters);
  };

  // first time loading tickets - not filtering
  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (!event) return;
    const offersParam = searchParams.get("offers");
    const selectedOfferIds = offersParam?.trim()
      ? offersParam
          .split(",")
          .filter((id) => id.trim()) // Remove empty strings
          .map((id) => parseInt(id, 10))
      : [];

    const newFilters = {
      quantity: parseInt(searchParams.get("quantity")) || 2,
      sort: searchParams.get("sort") === "bestseat" ? "bestseat" : "price",
      accessible: searchParams.get("accessible") === "true",
      accessCodes: searchParams.get("access_code")?.split(",") || [],
      selectedOfferIds,
    };

    setFilters(newFilters);
    getQuickPicksAndListings(newFilters);
  }, [location.search, event]);

  return (
    <div className='ticketpanel-height card-xl-no-mobile card-xl-no-mobile--dark d-flex-column vjx-full'>
      {isLoading ? (
        <LoadingContainer />
      ) : (
        <>
          <div className='card-body-heading vjx-7 pxt-m'>
            <div className='tablet-desktop-only'>
              <Card.Title as='h5'>Select your seats!</Card.Title>
              <Card.Subtitle as='h6' className='vjx-lh'>
                Time to browse through the available options.
              </Card.Subtitle>
            </div>
            <FindOnMap
              extraClass='vjx-8'
              isScheduled={isScheduled}
              background={seatmapPreview}
            />
          </div>
          {!isScheduled && isMappingCreated && <FilterBar quickpicks={quickpicks} isLoadingTickets={isLoadingTickets} />}
          <ListingsContainer
            isMappingCreated={isMappingCreated}
            isScheduled={isScheduled}
            scheduledTime={scheduledTime}
            isLoadingTickets={isLoadingTickets}
            quickpicks={quickpicks}
            soldout={soldout}
            reloadQuickPicks={reloadQuickPicks}
            needToUnlock={needToUnlock}
          />
        </>
      )}
    </div>
  );
}
