import { useGACheckout } from "../../../NewGAPurchaseWrapper/GACheckoutProvider";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import { GATickets } from "./GATickets";

import "../getStatus.scss";
import "./getTickets.scss";
import { Fragment } from "react";
import { formatCurrency } from "../../../../../utilities/helpers";

// used for desktop and mobile
export default function GetTickets({
  event,
  quickpicks,
  selectQuickPick,
  lowestPrice,
  openCBModal,
  showTitle = true,
  asCard = true,
}) {
  const { checkingAvailability } = useGACheckout();
  return (
    <Fragment>
      <Card
        className={`${
          asCard
            ? "card-xl card-xl--dark get_tickets_desktop get_desktop w-100"
            : "bg-transparent border-0"
        }`}
      >
        <Card.Body className={`${asCard ? "" : "p-0"}`}>
          {showTitle && <Card.Title as='h5'>Get Tickets 🎟️</Card.Title>}
          <GATickets
            quickpicks={quickpicks}
            selectQuickPick={selectQuickPick}
            checkingAvailability={checkingAvailability}
            event={event}
          />
        </Card.Body>
      </Card>

      {/* Due to the nature of how this is built; just use asCard; lets not think about it */}
      {asCard && (
        <div className={`get_tickets_mobile get_mobile_fixed`}>
          <div>
            {lowestPrice && <p>From {formatCurrency(lowestPrice)}</p>}
            <span>No hidden costs, no suprises!</span>
          </div>
          <Button
            onClick={() => openCBModal("Select Ticket")}
            variant='primary'
            size='lg'
            className='m-0'
          >
            Buy Now
          </Button>
        </div>
      )}
    </Fragment>
  );
}
