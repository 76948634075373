import { useEffect, useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { useActions } from "../providers/ActionsProvider";
import { useTooltips } from "../providers/TooltipProvider";

import { Sections } from "./Sections";
import { Seats } from "./Seats";
import { Spinner } from "../../../../../../LoadingContainer/Spinner";
import useSeatmapStore from "../../../../../../../stores/seatmapStore";

export default function SeatMap({
  loadingComplete,
  mapError,
  setMapError,
  accessCodes,
}) {
  // Zustand store
  const background = useSeatmapStore(state => state.background);
  const maxScale = useSeatmapStore(state => state.maxScale);
  const { hideTips } = useTooltips();
  const {
    svgRef,
    zoomRef,
    hidePath,
    checkViewBoxIntersection,
    afterZoomChange,
  } = useActions();
  const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(
    !background?.url
  );

  const onZoomStop = (ref, _) => {
    const scaleFactor = ref.state.scale;
    afterZoomChange(scaleFactor);
  };

  const onPanningStop = (ref, event) => {
    if (hidePath.current) {
      checkViewBoxIntersection();
    }
  };

  // Unlock on map
  useEffect(() => {
    hideTips();
  }, [accessCodes]);

  // Load background image
  useEffect(() => {
    if (!background?.url) return;

    const img = new Image();
    setIsBackgroundLoaded(false);

    img.onload = () => setIsBackgroundLoaded(true);
    img.onerror = () => {
      console.error("Failed to load background image");
      setIsBackgroundLoaded(true);
      setMapError(true); // Still show the map even if background fails
    };

    img.src = background.url;
    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [background?.url]);

  if (!loadingComplete || !isBackgroundLoaded) {
    return (
      <div className='d-flex align-items-center justify-content-center full-height full-width'>
        <Spinner variant='primary' size='lg' />
      </div>
    );
  }

  if (mapError) {
    return (
      <div className='d-flex align-items-center justify-content-center full-height full-width'>
        <span>Cannot display map...</span>
      </div>
    );
  }

  return (
    <TransformWrapper
      ref={zoomRef}
      initialScale={1}
      minScale={1}
      maxScale={maxScale || 20}
      doubleClick={{ disabled: true }}
      wheel={{ step: 0.2 }}
      panning={{ velocityDisabled: true }}
      onZoomStop={onZoomStop}
      onPanningStop={onPanningStop}
      centerOnInit={true}
      onPanningStart={hideTips}
      onZoomStart={hideTips}
    >
      <TransformComponent>
        <svg
          ref={svgRef}
          id='primary-svg'
          data-component='svg'
          aria-hidden='true'
          className='map_svg'
          viewBox={`0 0 ${background?.width} ${background?.height}`}
          style={{ backgroundImage: `url(${background?.url})` }}
        >
          <Seats />
          <Sections
            hidePath={hidePath}
            checkViewBoxIntersection={checkViewBoxIntersection}
          />
        </svg>
      </TransformComponent>
    </TransformWrapper>
  );
}
