import React, { createContext, useContext, useRef, useState } from "react";
import SideBottomModal from "./SideBottomModal/SideBottomModal";

// Create the context
const SecondModalContext = createContext(undefined);

export const useSecondModal = () => {
  const context = useContext(SecondModalContext);
  if (!context) {
    throw new Error("Context must be used within a SecondModalProvider");
  }
  return context;
};

// Provide certain type of modal used in the page
export const SecondModalProvider = ({ children }) => {
  const [selectedFromQuickpicks, setSelectedFromQuickpicks] = useState(null);
  const dialogRef = useRef(null);

  const changeQuickpickQuantity = (newQuantity, isOfferDiscount, offer) => {
    setSelectedFromQuickpicks((prev) => {
      const quantity =
        typeof newQuantity === "string" ? Number(newQuantity) : newQuantity;

      if (isOfferDiscount && offer) {
        return {
          ...prev,
          offer: {
            ...prev.offer,
            offer_discounts: prev.offer.offer_discounts.map((discount) =>
              discount.id === offer.id ? { ...discount, quantity } : discount
            ),
          },
        };
      }

      return {
        ...prev,
        quantity,
      };
    });
  };

  const openModal = (pick, quantity) => {
    setSelectedFromQuickpicks({ ...pick, quantity });
    dialogRef.current.showModal();
  };

  const closeModal = () => {
    dialogRef.current.close();
  };

  return (
    <SecondModalContext.Provider
      value={{
        openModal,
        closeModal
      }}
    >
      {children}
      <SideBottomModal dialogRef={dialogRef} handleClose={closeModal} selectedFromQuickpicks={selectedFromQuickpicks} changeQuickpickQuantity={changeQuickpickQuantity} />
    </SecondModalContext.Provider>
  );
};
