import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"

import { ActionsProvider } from "../../../PurchaseFlow/NewSeatedPurchaseWrapper/providers/SeatmapProvider/SeatmapWrapper/providers/ActionsProvider";
import { usePackageSeatmap } from "../PackageSeatmapProvider";
import { SeatMap } from "../../../PurchaseFlow/NewSeatedPurchaseWrapper/providers/SeatmapProvider/SeatmapWrapper/SeatMap";

import { placePackageIntoCart } from "../../../../utilities/api";

import { TooltipProvider } from "../../../PurchaseFlow/NewSeatedPurchaseWrapper/providers/SeatmapProvider/SeatmapWrapper/providers/TooltipProvider";
import useSeatmapStore from "../../../../stores/seatmapStore";

export default function PackageSeatmapWrapper({ handleClose }) {
    // Zustand
    const setSeatLookupTable = useSeatmapStore(state => state.setSeatLookupTable);
    const setSectionLookupTable = useSeatmapStore(state => state.setSectionLookupTable);
    const selectedFromMap = useSeatmapStore(state => state.selectedFromMap);
    const data = useSeatmapStore(state => state.data);

    const navigate = useNavigate()
    const { eventPackage, purchaseLog, setSeatedError } = usePackageSeatmap();

    const [mapError, setMapError] = useState(false);
    const [checkingAvailability, setCheckingAvailability] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [loadingComplete, setLoadingComplete] = useState(false);

    const checkout = () => {
        setCheckingAvailability(true);
        let data = {
            packageTickets: selectedFromMap,
            eventPackageId: eventPackage.id,
        };

        placePackageIntoCart(data)
            .then((res) => {
                console.log("placePackageIntoCart res: ", res.data);
                sessionStorage.setItem(
                    "cart",
                    JSON.stringify({ cartId: res.data.cartId })
                );
                navigate(`/checkout`);
            })
            .catch((err) => {
                if (err.response) {
                    setSeatedError({
                        status: err.response.status,
                        title: "Selected tickets not available",
                        message: err.response.data.error.message || "An error occurred",
                        buttonText: "Return to tickets list",
                    });
                } else {
                    setSeatedError({
                        status: 500,
                        title: "Selected tickets not available",
                        message: "Network error occurred",
                        buttonText: "Return to tickets list",
                    });
                }
            })
            .finally(() => {
                setCheckingAvailability(false);
            });
    };

    const createSeatLookupTable = (seatedTickets, eventPackage) => {
        const { ticket_groups, package_groups, package_tickets, ...packageWithoutTicketGroups } = eventPackage;
        const lookupTable = {};

        seatedTickets.forEach((ticket) => {
            // Create a single reference object for this ticket group
            const ticketGroupRef = {
                ...ticket,
                resale: false,
                offer: {
                    name: packageWithoutTicketGroups.name,
                },
            };

            lookupTable[ticket.seatId] = ticketGroupRef;
        });

        // Delete instances sold in sibling packages
        const soldInOtherPackages = purchaseLog.sold_package_tickets;
        soldInOtherPackages.forEach((soldSeat) => {
            if (lookupTable[soldSeat.seatId]) {
                delete lookupTable[soldSeat.seatId];
            }
        })

        return lookupTable;
    };

    const createSectionLookupTable = (tickets, eventPackage) => {
        const { package_tickets, ...packageWithoutTicketGroups } = eventPackage;
        const lookupTable = {};

        tickets.forEach((ticket) => {
            const { sectionId, seatId } = ticket;

            if (!lookupTable[sectionId]) {
                // Initialize first entry for this section
                lookupTable[sectionId] = [{
                    ...ticket,
                    package: {
                        name: packageWithoutTicketGroups.name,
                        minQuantity: packageWithoutTicketGroups.minQuantity,
                        maxQuantity: packageWithoutTicketGroups.maxQuantity,
                    },
                    availableCount: 1
                }];
            } else {
                lookupTable[sectionId][0].availableCount += 1;
            }
        });

        const soldInOtherPackages = purchaseLog?.section_sold_counts;
        if (purchaseLog && soldInOtherPackages) {
            Object.keys(lookupTable).forEach((sectionId) => {
                const sectionEntry = lookupTable[sectionId][0];
                const soldCount = soldInOtherPackages.find((soldCount) => soldCount.sectionId === sectionId);
                if (!soldCount) return;

                const sectionCapacity = data.sections?.[sectionId]?.spots?.length;
                if (!sectionCapacity) return;

                const remainingCapacity = sectionCapacity - soldCount.soldCount;
                if (remainingCapacity < sectionEntry.availableCount) {
                    sectionEntry.availableCount = Math.max(0, remainingCapacity);
                }
            })
        }

        console.log("sectionlook: ", lookupTable);
        return lookupTable;
    };

    useEffect(() => {
        // Calculate total price
        const newTotalPrice = selectedFromMap.map((ticketGroup) => {
            if (ticketGroup.GA) {
                return ticketGroup.quantity * ticketGroup.price;
            } else {
                return ticketGroup.price;
            }
        }).reduce((sum, price) => sum + price, 0);
        const seatedCount = selectedFromMap
            .filter((ticketGroup) => ticketGroup.GA === false)
            .map((ticketGroup) => ticketGroup.seatId).length;
        const gaCount = selectedFromMap
            .filter((ticketGroup) => ticketGroup.GA === true)
            .reduce((sum, ticketGroup) => sum + ticketGroup.quantity, 0);

        const newTotalCount = seatedCount + gaCount;
        setTotalCount(newTotalCount);
        setTotalPrice(newTotalPrice);
    }, [selectedFromMap]);

    useEffect(() => {
        let getMapAvailability = async () => {
            try {
                console.log("eventPackage: ", eventPackage);
                const packageTickets = eventPackage?.package_tickets;
                if (!packageTickets) return;

                const unSoldTickets = packageTickets.filter((ticket) => ticket.on_sale_status === "available")
                const seatedTickets = unSoldTickets.filter((ticket) => !ticket.GA);

                // Create lookup tables; allows for constant time checks
                const sectionLookupTable = createSectionLookupTable(
                    unSoldTickets,
                    eventPackage,
                    purchaseLog
                );
                setSectionLookupTable(sectionLookupTable);

                const seatLookupTable = createSeatLookupTable(
                    seatedTickets,
                    eventPackage,
                    purchaseLog
                );        
                setSeatLookupTable(seatLookupTable);

                setLoadingComplete(true);
                console.log("LOADING COMPLETED....");
            } catch (error) {
                console.log("ERROR in getSectionAvailability: ", error);
                setMapError(true);
                setLoadingComplete(true);
            }
        };

        getMapAvailability();
    }, [eventPackage]);

    return (
        <ActionsProvider
            handleClose={handleClose}
            loadingComplete={loadingComplete}
            totalCount={totalCount}
            totalPrice={totalPrice}
            checkout={checkout}
            checkingAvailability={checkingAvailability}
        >
            <TooltipProvider>
                <SeatMap
                    loadingComplete={loadingComplete}
                    mapError={mapError}
                    setMapError={setMapError}
                    accessCodes={null}
                />
            </TooltipProvider>
        </ActionsProvider>
    );
}
