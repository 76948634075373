import { create } from "zustand";

const useFiltersStore = create((set, get) => ({
  // State
  event: null,
  eventTicketLimit: null,
  code: null,
  feeStructure: null,
  taxRates: null,
  priceRangeMinMax: null,
  offers: [],
  originalPriceRange: [0, 500],
  filters: {
    quantity: 2,
    sort: "bestseat",
    accessible: false,
    priceRange: [0, 500],
    selectedOfferIds: [],
    accessCodes: [],
  },

  setFilters: (newFilters) =>
    set({ filters: { ...get().filters, ...newFilters } }),
  setEvent: (event) => set({ event }),
  setFeeStructure: (feeStructure) => set({ feeStructure }),
  setTaxRates: (taxRates) => set({ taxRates }),
  setPriceRangeMinMax: (priceRangeMinMax) => set({ priceRangeMinMax }),
  setOffers: (offers) => set({ offers }),
  setCode: (code) => set({ code }),
  setEventTicketLimit: (eventTicketLimit) => set({ eventTicketLimit }),
}));

export default useFiltersStore;
