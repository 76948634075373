import { ModalsProvider } from "./providers/ModalsProvider/ModalsProvider";
import { SeatmapProvider } from "./providers/SeatmapProvider";
import { SecondModalProvider } from "./providers/SecondModalProvider";
import { CheckoutProvider } from "./providers/CheckoutProvider";
import { TicketsProvider } from "./providers/TicketsProvider/TicketsProvider";
import { UnlockProvider } from "./providers/UnlockProvider/UnlockProvider";
import NewSeatedPurchaseContainer from "./NewSeatedPurchaseContainer/NewSeatedPurchaseContainer";
import { useEffect } from "react";
import { UpdatingProvider } from "./providers/SeatmapProvider/SeatmapWrapper/providers/UpdatingProvider/UpdatingProvider";

const consoleArt = `
░█░█░█▀▀░█░░░█░░░█▀█░░░█▀▄░█░█░█▀▄░█▀▀
░█▀█░█▀▀░█░░░█░░░█░█░░░█░█░█░█░█░█░█▀▀
░▀░▀░▀▀▀░▀▀▀░▀▀▀░▀▀▀░░░▀▀░░▀▀▀░▀▀░░▀▀▀

Wanna build the future of ticketing?
Head over to https://blocktickets.xyz/jobs
`;

export function NewSeatedPurchaseWrapper() {
    
  // Console message ( to fuck with the haters )
  useEffect(() => {
    // console.clear();
    // console.log(consoleArt);
  }, []);

  return (
    <TicketsProvider>
        <CheckoutProvider>
        <UnlockProvider>
            <SecondModalProvider>
            <ModalsProvider>
                <UpdatingProvider>
                <SeatmapProvider>
                    <NewSeatedPurchaseContainer />
                </SeatmapProvider>
                </UpdatingProvider>
            </ModalsProvider>
            </SecondModalProvider>
        </UnlockProvider>
        </CheckoutProvider>
    </TicketsProvider>
  );
}
