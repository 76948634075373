import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { TicketErrorModal } from "./TicketErrorModal";
import { placeTicketsIntoCart } from "../../../../../utilities/api";
import { useNavigate } from "react-router-dom";
import useSeatmapStore from "../../../../../stores/seatmapStore";
import useFiltersStore from "../../../../../stores/filtersStore";

// Create the context
const CheckoutContext = createContext(undefined);

export const useCheckout = () => {
    const context = useContext(CheckoutContext);
    if (!context) {
        throw new Error("Context must be used within a CheckoutProvider");
    }
    return context;
};

// Provide all functionality + modal for proceeding to checkout
export const CheckoutProvider = ({ children }) => {
    // Zustand
    const seatedError = useSeatmapStore(state => state.seatedError);
    const setSeatedError = useSeatmapStore(state => state.setSeatedError);
    const selectedFromMap = useSeatmapStore(state => state.selectedFromMap);
    const event = useFiltersStore(state => state.event);

    const dialogRef = useRef(null);
    const navigate = useNavigate()
    const [checkingAvailability, setCheckingAvailability] = useState(false);

    useEffect(() => {
        if (seatedError) {
            dialogRef.current.showModal();
        }
    }, [seatedError]);

    // This is only valid for a single ticket
    const checkout = (isQuickpick, selectedFromQuickpicks) => {
        setCheckingAvailability(true);
        let data = {
            ticketGroups: isQuickpick ? [selectedFromQuickpicks] : [...selectedFromMap],
            eventId: event.id,
        };

        console.log("placeTicketsIntoCart: ", data)
        placeTicketsIntoCart(data)
            .then((res) => {
                console.log("placeTicketsIntoCart res: ", res);
                sessionStorage.setItem("cart", JSON.stringify({ cartId: res.data.cartId }))
                navigate(`/checkout`);
            })
            .catch((err) => {
                if (err.response) {
                    setSeatedError({
                        status: err.response.status,
                        title: "Selected tickets not available",
                        message: err.response.data.error.message || "An error occurred",
                        buttonText: "Return to tickets list"
                    });
                } else {
                    setSeatedError({
                        status: 500,
                        title: "Selected tickets not available",
                        message: "Network error occurred",
                        buttonText: "Return to tickets list"
                    });
                }
            })
            .finally(() => {
                setCheckingAvailability(false);
            });
    };

    const resetSeatedError = () => {
        setSeatedError(null);
    };

    const modalClose = () => {
        resetSeatedError();
        dialogRef.current.close();
        // Probably just want to refresh here if tickets not available
    };

    return (
        <CheckoutContext.Provider
            value={{
                checkout,
                checkingAvailability,
                event
            }}
        >
            {children}
            <TicketErrorModal dialogRef={dialogRef} seatedError={seatedError} handleClose={modalClose} />
        </CheckoutContext.Provider>
    );
};
