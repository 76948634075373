import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { formatShortDate, getStartDateFormatter, formatShortAddress, sort, getTimezoneDate, timezones } from "./../../../../utilities/helpers";

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';

import './venueCardBody.scss';

export default function VenueCardBody({ venue }) {

    let timezone = timezones(venue?.timezone)

    return (
        <Fragment>
            <Card.Title as="h5">{venue?.name}</Card.Title>
            <Card.Text as="p">{formatShortAddress(venue)}</Card.Text>
            <hr />
            <ul className='venue-list'>
                {venue && venue.allEvents &&
                    sort(venue.allEvents).map((event, index) => {
                        return (
                            <li key={index}>
                                <Row>
                                    <span className="col caption">{formatShortDate(getTimezoneDate(event?.start, timezone), getStartDateFormatter(event))}</span>
                                    <span className="col caption caption-bold text-end">{event?.name}</span>
                                </Row>
                            </li>
                        )
                    })
                }
            </ul>
            <Card.Link
                as={Link}
                to={`/venue/${venue?.slug}`}
                className="btn btn-outline-light text-primary btn-sm">
                View All
            </Card.Link>
        </Fragment>
    );
}
