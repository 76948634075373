import React, { useLayoutEffect, useEffect, useState, useContext } from 'react';

import LoadingContext from '../../context/Loading/Loading';
import { useModals } from '../PurchaseFlow/NewCheckoutWrapper/providers/ModalsProvider';

import Stack from 'react-bootstrap/Stack'
import Card from 'react-bootstrap/Card'

import { PageLoadingContainer } from '../PageLoadingContainer';
import { BackButton } from '../BackButton';
import { PackageEventList } from '../PackageEventList';
import { Event } from '../PurchaseFlow/components/Event';

export default function PackageDetailsWrapper({ eventPackage, orderId }) {

    const { openModal } = useModals()

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)


    // useLayoutEffect(() => {
    //     const el = document.querySelector('#main-container');

    //     fullHeightContainer(el);

    //     return () => {
    //         removeFullHeightContainer(el);
    //     };
    // }, []);

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <section className="spacer-xs">
                    <div className='d-flex-column flex-lg-row gap-5'>
                        <div className="tablet-desktop-only">
                            <BackButton />
                        </div>
                        <Stack gap={4}>
                            <Event eventPackage={eventPackage} event={eventPackage?.events[0]} openModal={openModal} />
                            <Card body className='card-xl card-xl--dark'>
                                <Card.Title>Events & Tickets</Card.Title>
                                <PackageEventList events={eventPackage?.events} linkTo={`/event-details/${orderId}`} />
                            </Card>
                        </Stack>
                    </div>
                </section>
            )}
        </>
    );
}
