import React, { Fragment } from 'react';

import { formatShortDate, getStartDateFormatter, formatShortAddress, getTimezoneDate, timezones, getSinglarOrPluralWord } from '../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { Spinner } from "../LoadingContainer/Spinner"

import placeholder from '../../assets/placeholder.png'

export default function TransferCard({ transfer, cancel, status, acceptTransfer, isAccepting }) {
    let timezone = timezones(transfer?.event?.venue?.timezone)

    // only show loading spinner that is currently being accepted 
    const getText = (id) => {
        if (isAccepting.id === id) {
            if (isAccepting.accepting) {
                return <Spinner variant='dark' />
            } else {
                return 'Accept transfer'
            }

        } else {
            return 'Accept transfer'
        }
    }

    return (
        <Fragment>
            <Card body className="ticket-card">
                <Card.Img
                    src={transfer?.event?.image?.url || placeholder}
                    alt={`Cover art for ${transfer?.event?.name} event`}
                    width="217"
                    height="217"
                    className="event-image event-image-lg mb-3"
                />
                <div className="details d-flex-column">
                    <Card.Title as="h5" className='normal'>{transfer?.event?.name}</Card.Title>
                    <p className="event-details">
                        {formatShortDate(getTimezoneDate(transfer?.event?.start, timezone), getStartDateFormatter(transfer?.event))}<span className="venue">{transfer?.event?.venue?.name}</span><span className="loc">
                            {formatShortAddress(transfer?.event?.venue)}
                        </span>
                    </p>
                    <span className="num-tickets">{transfer?.tickets.length} {status ? 'Pending' : ''} {getSinglarOrPluralWord(transfer?.tickets?.length)}</span>
                    {
                        (!status && transfer?.status !== 'claimed') &&
                        <Button
                            variant="outline-light"
                            onClick={() => cancel('cancel', transfer)}
                            className="text-danger">
                            Cancel transfer
                        </Button>
                    }
                    {
                        (status && status === 'userAccepting') &&
                        <Button
                            variant="outline-light"
                            onClick={() => acceptTransfer(transfer)}
                            disabled={isAccepting?.accepting}
                            className="icon-button text-primary btn-h--sm">
                            {getText(transfer.id)}
                        </Button>
                    }
                </div>
            </Card>
        </Fragment>
    )
}