import { useEffect } from "react";
import { useModals } from "../providers/ModalsProvider/ModalsProvider";
import useSeatmapStore from "../../../../stores/seatmapStore";

import { Event } from "../../components/Event";
import { TicketSelectionPanel } from "./TicketSelectionPanel";

import "../../purchaseFlow.scss";
import "./newSeatedPurchaseContainer.scss";
import useFiltersStore from "../../../../stores/filtersStore";

export default function NewSeatedPurchaseContainer() {
    // Zustand
    const getTicketImage = useSeatmapStore(state => state.getTicketImage);
    const event = useFiltersStore(state => state.event);
    const { openCBModal } = useModals();

    // Hide intercom widget on this page
    // This does hide it until you refresh the page / turn it back on again
    useEffect(() => {
        if (window.Intercom) {
            window.Intercom("update", {
                hide_default_launcher: true,
            });
        }
    }, []);

    return (
        <div
            className={` max-h-screen purchaseflow-gradient`}
        >
            <div className='d-flex-column nstc-ss'>
                <div className="pxccc">
                    <Event
                        event={event}
                        openModal={openCBModal}
                    />
                </div>
                <TicketSelectionPanel
                    seatmapPreview={getTicketImage(event?.venue?.slug, 'preview', 'preview')}
                />
            </div>
        </div>
    );
}
