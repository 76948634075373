import React, { useEffect, useState, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { Helmet } from "react-helmet-async";

import LoadingContext from "../../context/Loading/Loading";

import {
    getEventByShortCode,
    getTaxRates,
} from "../../utilities/api";
import { formatDateTime, capitalizeString } from "../../utilities/helpers";

import Alert from "react-bootstrap/Alert";

import {
    NewSeatedPurchaseWrapper,
    PageLoadingContainer,
} from "../../components";
import useFiltersStore from "../../stores/filtersStore";

export default function NewSeatedPurchasePage() {
    // Zustand
    const setEventTicketLimit = useFiltersStore(state => state.setEventTicketLimit);
    const setEvent = useFiltersStore(state => state.setEvent);
    const setFeeStructure = useFiltersStore(state => state.setFeeStructure);
    const setTaxRates = useFiltersStore(state => state.setTaxRates);
    const setPriceRangeMinMax = useFiltersStore(state => state.setPriceRangeMinMax);
    const setOffers = useFiltersStore(state => state.setOffers);
    const setCode = useFiltersStore(state => state.setCode);
    const event = useFiltersStore(state => state.event);

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext);
    const [searchParams] = useSearchParams();
    let { shortcode, slug } = useParams();
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        showLoading();
        const code = searchParams.get("code") ? searchParams.get("code") : 0
        setCode(code);
        getEventByShortCode(shortcode, slug, code)
            .then((res) => {
                if (res.data?.status === 404) {
                    hideLoading();
                    setHasError(true);
                    return;
                }
                setHasError(false);
                setFeeStructure(res.data.event.fee_structure);
                setEvent(res.data?.event);
                setEventTicketLimit(res.data?.event.globalTicketLimit);
                setOffers(res.data?.availableOffers);
                const priceRange = findPriceRange(res.data?.availableOffers);
                setPriceRangeMinMax(priceRange);
                hideLoading();
            })
            .catch((err) => {
                console.error(err);
                hideLoading();
            });
    }, [shortcode]); // load event whenever event code changes 

    const findPriceRange = (offers) => {
        if (!offers || offers.length === 0) {
            return null;
        }

        let minPrice = Infinity;
        let maxPrice = -Infinity;

        offers.forEach(offer => {
            if (offer.am_pricing_objects) {
                offer.am_pricing_objects.forEach(priceObj => {
                    const price = parseFloat(priceObj.offerPrice);
                    if (!isNaN(price)) {
                        minPrice = Math.min(minPrice, price);
                        maxPrice = Math.max(maxPrice, price);
                    }
                });
            }
        });

        if (minPrice !== Infinity && maxPrice !== -Infinity) {
            return [minPrice, maxPrice];
        }

        return null;
    };

    const eventTaxRates = (city, state) => {
        console.log("eventTaxRates: ", city, state);
        getTaxRates(city, state)
            .then((res) => {
                setTaxRates(res?.data?.sales_tax_rates[0]);
            })
            .catch((err) => {
                console.error(err);
                hideLoading();
            });
    };

    return (
        <>
            {event && (
                <Helmet>
                    <meta charSet='utf-8' />
                    <meta
                        name='viewport'
                        content='width=device-width, initial-scale=1, maximum-scale=1'
                    />
                    <meta name='theme-color' content='#000000' />
                    <meta
                        name='keywords'
                        content={`${event?.name.trim()} Tickets, ${formatDateTime(
                            moment(event?.start),
                            "seoDate"
                        )}, ${event?.venue?.name?.trim()} - ${capitalizeString(
                            event?.venue?.address[0]?.city
                        )}, ${capitalizeString(event?.venue?.address[0]?.state)}`}
                    />
                    <title>{`Buy Ticket to ${event?.name.trim()} in ${capitalizeString(
                        event?.venue?.address[0]?.city
                    )} on ${formatDateTime(moment(event?.start), "seoDate")}`}</title>
                    {/* Facebook Meta Tags */}
                    <meta property='og:url' content={`${window.location.href}`} />
                    <meta property='og:type' content='website' />
                    <meta
                        property='og:title'
                        content={`Buy Ticket to ${event?.name.trim()} in ${capitalizeString(
                            event?.venue?.address[0]?.city
                        )} on ${formatDateTime(moment(event?.start), "seoDate")}`}
                    />
                    <meta
                        property='og:description'
                        content={`${event?.name.trim()} Tickets, ${formatDateTime(
                            moment(event?.start),
                            "seoDate"
                        )}, ${event?.venue?.name?.trim()} - ${capitalizeString(
                            event?.venue?.address[0]?.city
                        )}, ${capitalizeString(event?.venue?.address[0]?.state)}`}
                    />
                    <meta property='og:image' content={`${event?.image?.url}`} />
                    {/* Twitter Meta Tags */}
                    <meta name='twitter:card' content='summary_large_image' />
                    <meta property='twitter:domain' content={`${window.location.host}`} />
                    <meta property='twitter:url' content={`${window.location.href}`} />
                    <meta
                        name='twitter:title'
                        content={`Buy Ticket to ${event?.name.trim()} in ${capitalizeString(
                            event?.venue?.address[0]?.city
                        )} on ${formatDateTime(moment(event?.start), "seoDate")}`}
                    />
                    <meta
                        name='twitter:description'
                        content={`${event?.name.trim()} Tickets, ${formatDateTime(
                            moment(event?.start),
                            "seoDate"
                        )}, ${event?.venue?.name?.trim()} - ${capitalizeString(
                            event?.venue?.address[0]?.city
                        )}, ${capitalizeString(event?.venue?.address[0]?.state)}`}
                    />
                    <meta name='twitter:image' content={`${event?.image?.url}`} />
                </Helmet>
            )}
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <>
                    {event && !hasError && (
                        <NewSeatedPurchaseWrapper />
                    )}

                    {hasError && (
                        <Alert className='mt-4' variant={"danger"}>
                            The page you're looking for doesn't exist, please ensure the URL
                            provided was correct.
                        </Alert>
                    )}
                </>
            )}
        </>
    );
}
