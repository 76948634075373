import { createContext, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useFiltersStore from "../../../../../stores/filtersStore";

export const TicketContext = createContext();

export const useTickets = () => {
  const context = useContext(TicketContext);
  if (!context) {
    throw new Error("Context must be used within a TicketsProvider");
  }
  return context;
};

// Start calling this like searchParamsProvider...
export const TicketsProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // for map and listingsContainer
  const updateSearchParam = (key, value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);
    navigate(`${location.pathname}?${searchParams}`);
  };

  // For map and listingsContainer
  const multiUpdateSearchParam = (listOfKVs) => {
    const searchParams = new URLSearchParams(location.search);
    listOfKVs.forEach((kv) => {
      searchParams.set(kv[0], kv[1]);
    });
    navigate(`${location.pathname}?${searchParams}`);
  };

  return (
    <TicketContext.Provider
      value={{ updateSearchParam, multiUpdateSearchParam }}
    >
      {children}
    </TicketContext.Provider>
  );
};
