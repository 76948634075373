import React, { useState, useEffect, useContext } from 'react';

import LoadingContext from '../../../../../context/Loading/Loading';

import { getMyUpcomingOrders } from '../../../../../utilities/api';

import { PageLoadingContainer } from '../../../../PageLoadingContainer';
import { Invoice } from './Invoice'

export default function InvoiceWrapper({ id }) {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const [details, setDetails] = useState()

    const [invoice, setInvoice] = useState()

    const [ticketAmount, setTicketAmount] = useState(0);

    const [totalAmount, setTotalAmount] = useState(0);

    const [facilityFee, setFacilityFee] = useState(0);

    const [serviceFee, setServiceFee] = useState(0);

    const [processingFee, setProcessingFee] = useState(0);

    const [tax, setTax] = useState(0);

    const [totalFees, setTotalFees] = useState(0);

    useEffect(() => {
        showLoading();
        getMyUpcomingOrders()
            .then((res) => {
                console.log("InvoiceWrapper getMyUpcomingOrders: ", res.data)
                setDetails(res.data)
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }, [])

    useEffect(() => {
        if (!details) return

        let currInvoice = details.find(d => d.id == id)

        setInvoice(currInvoice)

        // TODO: use event fee structure to get fees and taxes 
        if (currInvoice?.details?.listings?.length > 0) {
            setTicketAmount(currInvoice?.details?.listings[0]?.askingPrice)
            setTotalAmount(currInvoice?.details?.listings[0]?.total)
            setFacilityFee(currInvoice?.details?.listings[0]?.pricing?.ticketFacilityFee * currInvoice?.details?.listings[0]?.quantity)
            setServiceFee(currInvoice?.details?.listings[0]?.pricing?.serviceFees * currInvoice?.details?.listings[0]?.quantity)
            setProcessingFee(currInvoice?.details?.listings[0]?.pricing?.paymentProcessingFee * currInvoice?.details?.listings[0]?.quantity)
            setTax(currInvoice?.details?.listings[0]?.pricing?.taxPerTicket * currInvoice?.details?.listings?.quantity)
            setTotalFees(currInvoice?.details?.listings[0]?.pricing?.totalFeesWithProcessingFee * currInvoice?.details?.listings[0]?.quantity)
        } else {
            setTicketAmount(currInvoice?.tickets[0]?.cost)
            setTotalAmount(currInvoice?.tickets[0]?.cost * currInvoice?.tickets?.length)
            setFacilityFee(currInvoice?.tickets[0]?.facilityFee * currInvoice?.tickets?.length)
            setServiceFee(currInvoice?.details?.ticket?.pricing?.serviceFees * currInvoice?.details?.ticketCount)
            setProcessingFee((currInvoice?.details?.ticket?.pricing?.processingFee + currInvoice?.details?.ticket?.pricing?.serviceFees + currInvoice?.details?.ticket?.pricing?.chargeBackProtection) * currInvoice?.details?.ticketCount)
            setTax(currInvoice?.details?.ticket?.pricing?.salesTax * currInvoice?.details?.ticketCount)
            setTotalFees((currInvoice?.details?.ticket?.pricing?.processingFee + currInvoice?.details?.ticket?.pricing?.serviceFees + currInvoice?.details?.ticket?.pricing?.chargeBackProtection + currInvoice?.details?.ticket?.pricing?.ticketFacilityFee + currInvoice?.details?.ticket?.pricing?.salesTax) * currInvoice?.details?.ticketCount)
        }
        hideLoading()
    }, [details])

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <Invoice invoice={invoice} ticketAmount={ticketAmount} totalAmount={totalAmount} facilityFee={facilityFee} serviceFee={serviceFee} processingFee={processingFee} tax={tax} totalFees={totalFees} />
            )}
        </>
    );
}
