import { useEffect, useState } from "react";

import { useGACheckout } from "../GACheckoutProvider";
import { useGAModals } from "../GAModalsProvider";

import { timezones } from "../../../../utilities/helpers";

import { BannerImage } from "../../components/BannerImage";
import { AdditionalInfo } from "../../components/AdditionalInfo";
import { MainDescription } from "../../components/MainDescription";
import { GetTickets } from "../../components/GetStatus/GetTickets";
import { GetSoldout } from "../../components/GetStatus/GetSoldout";
import { AboutSection } from "../../components/AboutSection";
import { Lineup } from "../../components/Lineup";
import { VenueSection } from "../../components/VenueSection";
import { Countdown } from "../../components/Countdown";
import { LoadingContainer } from "../../../LoadingContainer";

export default function GAPurchaseContainer({
    selectedFromQuickpicks,
    event,
    isScheduled,
    scheduledTime,
    quickpicks,
    isSoldout,
    selectQuickPick,
    isLoading,
}) {
    const { openCBModal } = useGAModals();
    const { checkout } = useGACheckout();

    const [eventStatus, setEventStatus] = useState("on_sale"); // ['forsale', 'presale', 'soldout']
    const [lowestPrice, setLowestPrice] = useState(null);

    // Testing set event status based on query param 'status'
    useEffect(() => {
        const statusParam = event?.status;
        if (statusParam) {
            switch (statusParam) {
                case "on_sale":
                    setEventStatus("on_sale");
                    break;
                case "pre_sale":
                    setEventStatus("pre_sale");
                    break;
                case "sold_out":
                    setEventStatus("sold_out");
                    break;
                default:
                    break;
            }
            return;
        }
    }, [event]);

    useEffect(() => {
        if (selectedFromQuickpicks) {
            checkout();
        }
    }, [selectedFromQuickpicks]);

    useEffect(() => {
        if (quickpicks && quickpicks.length > 0) {
            const quickpickPrices = quickpicks
                .filter((quickpick) => quickpick && quickpick.price != null)
                .map((quickpick) => quickpick.price);

            // any connected offers 
            const discountPrices = quickpicks
                .filter((quickpick) => quickpick?.offer?.connected_offers?.length > 0) // Ensure quickpick has some connected_offers
                .flatMap((quickpick) =>
                    quickpick.offer.connected_offers.flatMap((offer) =>
                        offer?.am_pricing_objects?.map((pricing) => pricing?.totalDue)
                    )
                );

            const validPrices = [...quickpickPrices, ...(discountPrices?.length > 0 ? discountPrices : [])]

            if (validPrices.length > 0) {
                const lowest = Math.min(...validPrices);
                setLowestPrice(lowest);
            } else {
                setLowestPrice(null);
            }
        } else {
            setLowestPrice(null);
        }
    }, [quickpicks]);

    return (
        <div className='new-tickets-page purchaseflow-gradient'>
            <div className='sticky-content'>
                <BannerImage event={event} />
                <div className='tablet-desktop-only'>
                    <AdditionalInfo
                        MTDescription={"Securely stored in your account."}
                        BPDescription={"Safe from bots and scalpers."}
                    />
                </div>
            </div>

            <div className='tickets_col'>
                <MainDescription event={event} />
                <ContentRenderer
                    isScheduled={isScheduled}
                    scheduledTime={scheduledTime}
                    isSoldout={isSoldout}
                    event={event}
                    isLoading={isLoading}
                    quickpicks={quickpicks}
                    selectQuickPick={selectQuickPick}
                    openCBModal={openCBModal}
                    lowestPrice={lowestPrice}
                />
                <AboutSection title='About' summary={event?.summary} />
                <Lineup attractions={event?.attractions} />
                <VenueSection event={event} />
                <div className='mobile-only'>
                    <AdditionalInfo
                        MTDescription={"Securely stored in your account."}
                        BPDescription={"Safe from bots and scalpers."}
                    />
                </div>
            </div>
        </div>
    );
}

function ContentRenderer({ isScheduled, scheduledTime, isSoldout, event, quickpicks, selectQuickPick, isLoading, lowestPrice, openCBModal }) {
    let timezone = timezones(event?.venue?.timezone);

    if (isLoading) {
        return <LoadingContainer />
    }

    if (isSoldout) {
        return <GetSoldout fixedMobile={true} />;
    }

    if (isScheduled && scheduledTime) {
        return (
            <Countdown scheduledTime={scheduledTime} timezone={timezone} />
        )
    }

    return (
        <GetTickets
            event={event}
            quickpicks={quickpicks}
            selectQuickPick={selectQuickPick}
            openCBModal={openCBModal}
            lowestPrice={lowestPrice}
        />
    );
}
