import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

import * as d3 from "d3";

import ZoomLevel from "./ZoomLevel/ZoomLevel";
import Legend from "./Legend/Legend";
import MapSheet from "./MapSheet/MapSheet";
import SheetBottom from "../../../../../../components/SheetBottom/SheetBottom";
import { MobileFSExit } from "./MobileFSExit";

import "./actionsProvider.scss";
import useSeatmapStore from "../../../../../../../../stores/seatmapStore";

// Create the context
const ActionsContext = createContext(undefined);

export const useActions = () => {
    const context = useContext(ActionsContext);
    if (!context) {
        throw new Error("Context must be used within a ActionsProvider");
    }
    return context;
};

// Create the provider component
export const ActionsProvider = ({
    handleClose,
    loadingComplete,
    mapError,
    totalCount,
    totalPrice,
    checkout,
    checkingAvailability,
    children,
}) => {
    // Zustand
    const setData = useSeatmapStore(state => state.setData);
    const scaleThreshold = useSeatmapStore(state => state.scaleThreshold);
    const setScale = useSeatmapStore(state => state.setScale);
    const setArePathsHidden = useSeatmapStore(state => state.setArePathsHidden);

    // Provider state
    const containerRef = useRef(null);
    const svgRef = useRef(null);
    const zoomRef = useRef(null);
    const dialogRef = useRef(null);
    const hidePath = useRef(false);
    // Sheet controls
    const [desktopSheetOpen, setDesktopSheetOpen] = useState(false);

    // Open a sheet when user has selected one or more seats
    useEffect(() => {
        if (totalCount > 0) {
            if (window.innerWidth > 768) {
              setDesktopSheetOpen(true);
            }
        } else {
            setDesktopSheetOpen(false);
            closeMobileSheet();
        }
    }, [totalCount]);

    // Check if the sections are intersecting with view, if yes, draw seats
    const checkViewBoxIntersection = () => {
        const INTERSECTION_THRESHOLD = 1;
        // Get container boundingBox
        const container = containerRef.current.getBoundingClientRect();
        const [left1, top1, right1, bottom1] = [
            container.left,
            container.top,
            container.right,
            container.bottom,
        ];
        // Get polygons/sections from the svg
        const d3Svg = d3.select(svgRef.current);
        const svgChildren = d3Svg.selectChild(".polygons").selectChildren("g");

        const currentData = useSeatmapStore.getState().data;
        const newSections = { ...currentData.sections };
        let hasChanged = false;
        const changedSection = [];
        // Loop through polygons/sections
        svgChildren._groups[0].forEach((element) => {
            // Get sectionId from the element
            let sectionId = element.getAttribute("data-section-id");
            // Get sections boundingClientRect
            const child = element.getBoundingClientRect();
            const [left2, top2, right2, bottom2] = [
                child.left,
                child.top,
                child.right,
                child.bottom,
            ];
            // Check if the container is intersecting with the section
            const intersecting =
                left2 <= right1 - INTERSECTION_THRESHOLD &&
                top2 <= bottom1 - INTERSECTION_THRESHOLD &&
                bottom2 >= top1 - INTERSECTION_THRESHOLD &&
                right2 >= left1 - INTERSECTION_THRESHOLD;

            const wasIntersected = currentData.sections[sectionId]?.intersected;
            if (wasIntersected !== intersecting) {
              changedSection.push({ secN: currentData.sections[sectionId].sectionNumber, wasIntersected})
                hasChanged = true;
                newSections[sectionId] = {
                    ...newSections[sectionId],
                    intersected: intersecting,
                };
            }
        });

        if (hasChanged) {
          setData({
            ...currentData,  // spread current data
            sections: newSections
          });
        }
    };

    const afterZoomChange = (newScale) => {
        setScale(newScale);

        if (newScale > scaleThreshold) {
            hidePath.current = true;
            setArePathsHidden(true);
            checkViewBoxIntersection();
            return;
        }

        hidePath.current = false;
        setArePathsHidden(false);
    };

    const openMobileSheet = () => {
        let dialog = dialogRef.current;
        if (dialog) {
            dialog.showModal();
        }
    };

    const closeMobileSheet = () => {
        let dialog = dialogRef.current;
        if (dialog) {
            dialog.close();
        }
    };

    return (
        <ActionsContext.Provider
            value={{
                svgRef,
                zoomRef,
                containerRef,
                hidePath,
                checkViewBoxIntersection,
                afterZoomChange
            }}
        >
            <div id='map' ref={containerRef}>
                <MobileFSExit handleClose={handleClose} />
                {children}
                {loadingComplete && !mapError && (
                    <ControlsContainer
                        desktopSheetOpen={desktopSheetOpen}
                        zoomRef={zoomRef}
                        totalCount={totalCount}
                        totalPrice={totalPrice}
                        openMobileSheet={openMobileSheet}
                        checkout={checkout}
                        checkingAvailability={checkingAvailability}
                        afterZoomChange={afterZoomChange}
                    />
                )}
            </div>
            <dialog ref={dialogRef} className='mobile-map-sheet-dialog'>
                <MapSheet
                    canGoBack={true}
                    goBack={closeMobileSheet}
                    totalCount={totalCount}
                    totalPrice={totalPrice}
                    checkout={checkout}
                    checkingAvailability={checkingAvailability}
                />
            </dialog>
        </ActionsContext.Provider>
    );
};

const ControlsContainer = ({
    desktopSheetOpen,
    zoomRef,
    totalCount,
    totalPrice,
    openMobileSheet,
    checkout,
    checkingAvailability,
    afterZoomChange
}) => (
    <div className='controls-container'>
        <div className='vjx-cc-outer'>
            <Legend />
            <div className='vjx-cc-inner'>
                <ZoomLevel
                    zoomRef={zoomRef}
                    afterZoomChange={afterZoomChange}
                />
                {desktopSheetOpen && (
                    <MapSheet
                        totalCount={totalCount}
                        totalPrice={totalPrice}
                        checkout={checkout}
                        checkingAvailability={checkingAvailability}
                    />
                )}
            </div>
        </div>
        <div className='mobile-only'>
            {totalCount > 0 && (
                <SheetBottom
                    VSButton={true}
                    VSClick={openMobileSheet}
                    selectedTicketCount={totalCount}
                    totalTicketsPrice={totalPrice}
                    checkout={() => checkout(false)} // isQuickpick
                    checkingAvailability={checkingAvailability}
                />
            )}
        </div>
    </div>
);
