import React, { useEffect, useState, useContext } from 'react';

import LoadingContext from '../../../context/Loading/Loading';

import { getMyEvents, getBankAccount, getAvailableFunds, getMyUpcomingOrders } from '../../../utilities/api'

import { PageLoadingContainer } from '../../PageLoadingContainer';
import { SettingsWrapper } from '..'
import { WithdrawCards } from './WithdrawCards';
import { Invoices } from './Invoices';

import './withdrawInvoicesWrapper.scss';

export default function WithdrawInvoicesWrapper() {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const [account, setAccount] = useState();

    const [funds, setAvailableFunds] = useState();

    const [invoices, setInvoices] = useState();

    useEffect(() => {
        showLoading();
        getMyUpcomingOrders()
            .then((res) => {
                console.log("WithdrawInvoicesWrapper getMyUpcomingOrders: ", res.data)
                setInvoices(res.data)

                getBankAccount()
                    .then((res) => {
                        setAccount(res.data)

                        getAvailableFunds()
                            .then((res) => {
                                let data = res.data;
                                let payouts = data.map(d => d.payout);
                                let sum = payouts.reduce((a, b) => a + b, 0);
                                setAvailableFunds(sum);
                                hideLoading()
                            }).catch((err) => {
                                console.error(err)
                                hideLoading()
                            })
                    }).catch((err) => {
                        console.error(err)
                        hideLoading()
                    });
            })
            .catch((err) => {
                hideLoading()
                console.error(err)
            })

    }, [])

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <>
                    <SettingsWrapper>
                        <section id="withdraw">
                            <div className="settings-heading">
                                <h1 className="settings-title">Withdraw</h1>
                                <h2 className="settings-subtitle">Withdraw funds from your account.</h2>
                            </div>
                            <WithdrawCards account={account} funds={funds} />
                        </section>
                    </SettingsWrapper>
                    <section id="invoices">
                        <div id="invoices-heading-container">
                            <div className="settings-heading">
                                <h1 className="settings-title">Invoices</h1>
                                <h2 className="settings-subtitle">View / download your previous invoices and transactions below.</h2>
                            </div>
                        </div>
                        <Invoices invoices={invoices} />
                    </section>
                </>
            )}
        </>
    );
}
