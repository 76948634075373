import React, {
    createContext,
    Suspense,
    useContext,
    useRef,
    lazy,
    useState,
} from "react";
import { CenterBottomModal } from "../../../components/CenterBottomModal";
import useFiltersStore from "../../../../../stores/filtersStore";
import { useTickets } from "../TicketsProvider/TicketsProvider";
const LazyUnlockOffers = lazy(() => import("./UnlockOffers/UnlockOffers"));

// Create the context
const UnlockContext = createContext(undefined);

export const useUnlock = () => {
    const context = useContext(UnlockContext);
    if (!context) {
        throw new Error("Context must be used within a UnlockProvider");
    }
    return context;
};

// Provide certain type of modal used in the page
export const UnlockProvider = ({ children }) => {
    // Zustand
    const accessCodes = useFiltersStore(state => state.filters.accessCodes);
    const selectedOfferIds = useFiltersStore(state => state.filters.selectedOfferIds);
    const {multiUpdateSearchParam} = useTickets();

    const [offerToUnlock, setOfferToUnlock] = useState([]);
    const [inputCode, setInputCode] = useState("");
    const [error, setError] = useState("");

    const dialogRef = useRef(null);

    const openUnlockModal = (offer) => {
        setOfferToUnlock(offer);
        dialogRef.current.showModal();
    };

    const closeUnlockModal = () => {
        dialogRef.current.close();
        setInputCode("")
        setError("")
    };

    return (
        <UnlockContext.Provider
            value={{
                openUnlockModal,
                closeUnlockModal,
            }}
        >
            {children}
            <CenterBottomModal
                dialogRef={dialogRef}
                handleClose={closeUnlockModal}
                title={"Unlock offers"}
                blurred={true}
            >
                <Suspense fallback={null}>
                    <LazyUnlockOffers
                        offer={offerToUnlock}
                        accessCodes={accessCodes}
                        selectedOfferIds={selectedOfferIds}
                        inputCode={inputCode}
                        setInputCode={setInputCode}
                        error={error}
                        setError={setError}
                        multiUpdateSearchParam={multiUpdateSearchParam}
                        closeUnlockModal={closeUnlockModal}
                    />
                </Suspense>
            </CenterBottomModal>
        </UnlockContext.Provider>
    );
};
