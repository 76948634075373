import React from 'react';

import { ModalsProvider } from '../../components/PurchaseFlow/NewCheckoutWrapper/providers/ModalsProvider';
import { TicketsProvider } from '../../components/PurchaseFlow/NewSeatedPurchaseWrapper/providers/TicketsProvider/TicketsProvider';

import { CheckoutSuccessWrapper } from '../../components/PurchaseFlow/CheckoutSuccessWrapper';

export default function CheckoutSuccessPage() {

    // let { orderId } = useParams(); might want to use for orderId

    return (
        <ModalsProvider>
            <CheckoutSuccessWrapper />
        </ModalsProvider>
    )
}
