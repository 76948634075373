import React, {
    createContext,
    Suspense,
    useContext,
    useRef,
    lazy,
    useState,
    useEffect,
} from "react";
import { CenterBottomModal } from "../../components/CenterBottomModal";
import { useNavigate, useLocation } from "react-router-dom";
const LazyUnlockOffers = lazy(() =>
    import(
        "../../NewSeatedPurchaseWrapper/providers/UnlockProvider/UnlockOffers/UnlockOffers"
    )
);

// Create the context
const GAUnlockContext = createContext(undefined);

export const useGAUnlock = () => {
    const context = useContext(GAUnlockContext);
    if (!context) {
        throw new Error("Context must be used within a GAUnlockProvider");
    }
    return context;
};

// Provide certain type of modal used in the page
export const GAUnlockProvider = ({ children, accessCodes, multiUpdateSearchParam }) => {
    const [offerToUnlock, setOfferToUnlock] = useState([]);
    const [inputCode, setInputCode] = useState("")
    const [error, setError] = useState("")

    const dialogRef = useRef(null);

    const openUnlockModal = (offer) => {
        setOfferToUnlock(offer);
        dialogRef.current.showModal();
    };

    const closeUnlockModal = () => {
        dialogRef.current.close();
        setInputCode("")
        setError("")
    };

    return (
        <GAUnlockContext.Provider
            value={{
                openUnlockModal,
                closeUnlockModal,
            }}
        >
            {children}
            <CenterBottomModal
                dialogRef={dialogRef}
                handleClose={closeUnlockModal}
                title={"Unlock offers"}
                blurred={true}
            >
                <Suspense fallback={null}>
                    <LazyUnlockOffers
                        offer={offerToUnlock}
                        accessCodes={accessCodes}
                        multiUpdateSearchParam={multiUpdateSearchParam}
                        selectedOfferIds={[]}
                        inputCode={inputCode}
                        setInputCode={setInputCode}
                        error={error}
                        setError={setError}
                        closeUnlockModal={closeUnlockModal}
                    />
                </Suspense>
            </CenterBottomModal>
        </GAUnlockContext.Provider>
    );
};
