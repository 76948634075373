import useSeatmapStore from "../../../../../../../../stores/seatmapStore";
import { Seat } from "./Seat";

const Icons = () => (
  <defs>
    <symbol id='icon-selected' viewBox='0 0 24 24'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.7071 6.29289C21.0976 6.68342 21.0976 7.31658 20.7071 7.70711L12.1213 16.2929C10.9497 17.4645 9.05025 17.4645 7.87868 16.2929L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929C4.68342 10.9024 5.31658 10.9024 5.70711 11.2929L9.29289 14.8787C9.68342 15.2692 10.3166 15.2692 10.7071 14.8787L19.2929 6.29289C19.6834 5.90237 20.3166 5.90237 20.7071 6.29289Z'
        fill='#FCFCFD'
      />
    </symbol>
    <symbol id='icon-resale' viewBox='0 0 20 20'>
      <path
        d='M3.33329 9.99984C3.33329 6.31794 6.31806 3.33317 9.99996 3.33317C12.0975 3.33317 13.9406 4.30275 15.1518 5.82751L13.7524 5.82341C13.2922 5.82206 12.918 6.19406 12.9166 6.6543C12.9153 7.11453 13.2873 7.48872 13.7475 7.49007L16.6166 7.49847C16.6486 7.50043 16.6808 7.50054 16.7131 7.49876L17.0809 7.49983C17.3023 7.50048 17.5149 7.41297 17.6717 7.25662C17.8285 7.10027 17.9166 6.88794 17.9166 6.6665L17.9166 3.33317C17.9166 2.87293 17.5435 2.49984 17.0833 2.49984C16.6231 2.49984 16.25 2.87293 16.25 3.33317L16.25 4.54106C14.7395 2.78349 12.5191 1.6665 9.99996 1.6665C5.39759 1.6665 1.66663 5.39746 1.66663 9.99984C1.66663 10.4601 2.03972 10.8332 2.49996 10.8332C2.9602 10.8332 3.33329 10.4601 3.33329 9.99984Z'
        fill='#FCFCFD'
      />
      <path
        d='M16.6667 9.99984C16.6667 13.6817 13.6819 16.6665 10 16.6665C7.9025 16.6665 6.05936 15.6969 4.84817 14.1722L6.2476 14.1763C6.70783 14.1776 7.08202 13.8056 7.08337 13.3454C7.08472 12.8851 6.71272 12.511 6.25248 12.5096L3.38343 12.5012C3.35142 12.4992 3.31921 12.4991 3.28694 12.5009L2.91915 12.4998C2.69771 12.4992 2.48512 12.5867 2.32832 12.7431C2.17151 12.8994 2.08337 13.1117 2.08337 13.3332L2.08338 16.6665C2.08338 17.1267 2.45647 17.4998 2.91671 17.4998C3.37695 17.4998 3.75004 17.1267 3.75004 16.6665L3.75004 15.4586C5.2605 17.2162 7.48089 18.3332 10 18.3332C14.6024 18.3332 18.3334 14.6022 18.3334 9.99984C18.3334 9.5396 17.9603 9.1665 17.5 9.1665C17.0398 9.1665 16.6667 9.5396 16.6667 9.99984Z'
        fill='#FCFCFD'
      />
    </symbol>
    <symbol id='icon-vip' viewBox='0 0 20 20'>
      <path
        d='M9.23489 2.60667C9.5244 1.9358 10.4757 1.9358 10.7652 2.60667L12.5818 6.81658C12.7044 7.10065 12.9743 7.29342 13.2828 7.31726L17.9417 7.67725C18.6891 7.73501 18.9861 8.6734 18.408 9.15072L14.9044 12.0434C14.6572 12.2475 14.549 12.5752 14.626 12.8863L15.7043 17.2432C15.8816 17.9597 15.1087 18.536 14.4726 18.1615L10.4228 15.7774C10.1619 15.6238 9.83817 15.6238 9.57726 15.7774L5.52748 18.1615C4.89139 18.536 4.11845 17.9597 4.29578 17.2432L5.37406 12.8863C5.45105 12.5752 5.34282 12.2475 5.09569 12.0434L1.59204 9.15072C1.01391 8.67339 1.31091 7.73501 2.0584 7.67725L6.71727 7.31726C7.02575 7.29342 7.29562 7.10065 7.41821 6.81658L9.23489 2.60667Z'
        fill='#FCFCFD'
      />
    </symbol>
    <symbol id='icon-locked' viewBox='0 0 20 20'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.59265 11.024C2.72614 9.17698 4.17813 7.74297 6.02625 7.62549C7.13148 7.55524 8.48288 7.5 10 7.5C11.5171 7.5 12.8685 7.55524 13.9737 7.62549C15.8219 7.74297 17.2739 9.17698 17.4074 11.024C17.4612 11.7695 17.5 12.5708 17.5 13.3333C17.5 14.0959 17.4612 14.8972 17.4074 15.6427C17.2739 17.4897 15.8219 18.9237 13.9737 19.0412C12.8685 19.1114 11.5171 19.1667 10 19.1667C8.48288 19.1667 7.13148 19.1114 6.02625 19.0412C4.17813 18.9237 2.72614 17.4897 2.59265 15.6427C2.53876 14.8972 2.5 14.0959 2.5 13.3333C2.5 12.5708 2.53876 11.7695 2.59265 11.024ZM11.6667 12.5C11.6667 13.1169 11.3315 13.6555 10.8333 13.9437V15C10.8333 15.4602 10.4602 15.8333 10 15.8333C9.53976 15.8333 9.16667 15.4602 9.16667 15V13.9437C8.6685 13.6555 8.33333 13.1169 8.33333 12.5C8.33333 11.5795 9.07953 10.8333 10 10.8333C10.9205 10.8333 11.6667 11.5795 11.6667 12.5Z'
        fill='#FCFCFD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.83337 5.00016C5.83337 2.69898 7.69885 0.833496 10 0.833496C12.3012 0.833496 14.1667 2.69898 14.1667 5.00016V8.3335C14.1667 8.79373 13.7936 9.16683 13.3334 9.16683C12.8731 9.16683 12.5 8.79373 12.5 8.3335V5.00016C12.5 3.61945 11.3808 2.50016 10 2.50016C8.61933 2.50016 7.50004 3.61945 7.50004 5.00016V8.3335C7.50004 8.79373 7.12694 9.16683 6.66671 9.16683C6.20647 9.16683 5.83337 8.79373 5.83337 8.3335V5.00016Z'
        fill='#FCFCFD'
      />
    </symbol>
    <symbol id='icon-unlocked' viewBox='0 0 20 20'>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.59265 11.024C2.72614 9.17698 4.17813 7.74297 6.02625 7.62549C7.13148 7.55524 8.48288 7.5 10 7.5C11.5171 7.5 12.8685 7.55524 13.9737 7.62549C15.8219 7.74297 17.2739 9.17698 17.4074 11.024C17.4612 11.7695 17.5 12.5708 17.5 13.3333C17.5 14.0959 17.4612 14.8972 17.4074 15.6427C17.2739 17.4897 15.8219 18.9237 13.9737 19.0412C12.8685 19.1114 11.5171 19.1667 10 19.1667C8.48288 19.1667 7.13148 19.1114 6.02625 19.0412C4.17813 18.9237 2.72614 17.4897 2.59265 15.6427C2.53876 14.8972 2.5 14.0959 2.5 13.3333C2.5 12.5708 2.53876 11.7695 2.59265 11.024ZM11.6667 12.5C11.6667 13.1169 11.3315 13.6555 10.8333 13.9437V15C10.8333 15.4602 10.4602 15.8333 10 15.8333C9.53976 15.8333 9.16667 15.4602 9.16667 15V13.9437C8.6685 13.6555 8.33333 13.1169 8.33333 12.5C8.33333 11.5795 9.07953 10.8333 10 10.8333C10.9205 10.8333 11.6667 11.5795 11.6667 12.5Z" fill="#FCFCFD"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.83337 4.16667C5.83337 1.86548 7.69885 0 10 0C12.3012 0 14.1667 1.86548 14.1667 4.16667V3.5C14.1667 3.96024 13.7936 4.33333 13.3334 4.33333C12.8731 4.33333 12.5 3.96024 12.5 3.5V4.16667C12.5 2.78595 11.3808 1.66667 10 1.66667C8.61933 1.66667 7.50004 2.78595 7.50004 4.16667V7.5C7.50004 7.96024 7.12694 8.33333 6.66671 8.33333C6.20647 8.33333 5.83337 7.96024 5.83337 7.5V4.16667Z" fill="#FCFCFD"/>
    </symbol>
    <symbol id='icon-accessible' viewBox='0 0 20 20'>
    <path d="M7.54081 3.64526C8.50281 3.64526 9.27681 2.86326 9.27681 1.92127C9.27681 0.965266 8.50281 0.197266 7.54081 0.197266C6.58281 0.197266 5.80481 0.965266 5.80481 1.92127C5.80481 2.86326 6.58281 3.64526 7.54081 3.64526ZM17.7288 15.4393L14.7688 10.3653C14.5848 10.0593 14.2628 9.88726 13.9288 9.87726H9.58481L9.54081 8.35126H12.6948C13.1248 8.32526 13.4788 8.00126 13.4788 7.56326C13.4788 7.13126 13.1348 6.79526 12.6948 6.78126H9.45281L9.34081 5.52526C9.27681 4.62126 8.49281 3.90926 7.57281 3.95926C6.64481 4.01126 5.94481 4.80326 5.98881 5.71926L6.29481 10.9513C6.37081 11.8693 7.14281 12.5193 8.05881 12.5193H13.6888L15.9888 16.4733C16.2648 16.9213 16.9028 17.1053 17.3828 16.8193C17.8568 16.5313 17.9988 15.9393 17.7288 15.4393ZM8.65681 18.4413C5.80881 18.4413 3.49881 16.1533 3.49881 13.3213C3.49881 11.7713 4.20681 10.3873 5.30881 9.44526L5.21281 7.84526C3.36881 8.98926 2.13281 10.9993 2.13281 13.3213C2.13281 16.8973 5.05081 19.7993 8.65681 19.7993C11.3108 19.7993 13.5788 18.2133 14.5988 15.9573L13.6828 14.3773C13.1908 16.6913 11.1348 18.4413 8.65681 18.4413Z" fill="white"/>
    </symbol>
  </defs>
);

export default function Seats() {
  // Zustand
  const data = useSeatmapStore(state => state.data);

  return (
    <g className='seats'>
      <Icons />
      {data?.sections &&
        Object.values(data?.sections).map((section, i) => {
          if (!section?.intersected || !section.zoomable) return null;
          return (
            <g key={i} data-section-id={section.sectionId}>
              {section.rows.map((rowId) => {
                const row = data?.rows[rowId];
                return (
                  <g key={rowId}>
                    {row.seats.map((seatId) => {
                      const seat = data.seats[seatId];
                      return (
                        <Seat
                          key={seatId}
                          seat={seat}
                        />
                      );
                    })}
                  </g>
                );
              })}
            </g>
          );
        })}
    </g>
  );
}
