import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { TicketErrorModal } from "../../NewSeatedPurchaseWrapper/providers/CheckoutProvider/TicketErrorModal";
import { placeGATicketsIntoCart } from "../../../../utilities/api";

// Create the context
const GACheckoutContext = createContext(undefined);

export const useGACheckout = () => {
    const context = useContext(GACheckoutContext);
    if (!context) {
        throw new Error("Context must be used within a CheckoutProvider");
    }
    return context;
};

// Provide all functionality + modal for proceeding to checkout
export const GACheckoutProvider = ({
    selectedFromQuickpicks,
    event,
    children,
}) => {
    const dialogRef = useRef(null);
    const navigate = useNavigate();

    const [seatedTickets, setSeatedTickets] = useState();
    const [seatedError, setSeatedError] = useState(null);
    const [checkingAvailability, setCheckingAvailability] = useState(false);

    useEffect(() => {
        if (seatedTickets && seatedTickets.success) {
            navigate(`/checkout?eventId=${event.uuid}`);
        }
    }, [seatedTickets]);

    useEffect(() => {
        if (seatedError) {
            dialogRef.current.showModal();
        }
    }, [seatedError]);

    // This is only valid for a single ticket
    const checkout = () => {
        setCheckingAvailability(true);
        let data = {
            ticketGroup: selectedFromQuickpicks,
            eventId: event.id,
        };

        console.log("placeGATicketsIntoCart data: ", data)
        placeGATicketsIntoCart(data)
            .then((res) => {
                sessionStorage.setItem("cart", JSON.stringify({ cartId: res.data.cartId }))
                setSeatedTickets(res.data);
            })
            .catch((err) => {
                if (err.response) {
                    setSeatedError({
                        status: err.response.status,
                        title: "Selected tickets not available",
                        message: err.response.data.error.message || "An error occurred",
                        buttonText: "Return to tickets list",
                    });
                } else {
                    setSeatedError({
                        status: 500,
                        title: "Selected tickets not available",
                        message: "Network error occurred",
                        buttonText: "Return to tickets list",
                    });
                }
            })
            .finally(() => {
                setCheckingAvailability(false);
            });
    };

    const resetSeatedError = () => {
        setSeatedError(null);
    };

    const modalClose = () => {
        resetSeatedError();
        dialogRef.current.close();
        // Probably just want to refresh here if tickets not available
    };

    const openTicketErrorModal = (title, message) => {
        setSeatedError({
            status: 500,
            title: title,
            message: message,
            buttonText: "Got it",
        });
    };

    return (
        <GACheckoutContext.Provider
            value={{
                checkout,
                checkingAvailability,
                event,
                openTicketErrorModal,
            }}
        >
            {children}
            <TicketErrorModal
                dialogRef={dialogRef}
                seatedError={seatedError}
                handleClose={modalClose}
            />
        </GACheckoutContext.Provider>
    );
};
