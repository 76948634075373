import React, { Fragment, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { removeBankAccount } from '../../../utilities/api';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

import { LinkBankAccountBtn } from './../LinkBankAccountBtn';
import { BankAccountDetailsModal } from '../BankAccountDetailsModal';
import { Spinner } from '../../LoadingContainer/Spinner';

import './bankAccount.scss';

export default function BankAccount({ account, getAccount, createBankAccount, removeBank }) {

    const [searchParams] = useSearchParams();

    const hasBankAccount = account && account?.external_accounts ? true : false;

    const [accountComplete, setAccountComplete] = useState(account && account?.charges_enabled && account?.payouts_enabled);

    const [
        show,
        setShow
    ] = useState(false);

    const [isSaving, setIsSaving] = useState(false);

    const handleClose = () => {
        setShow(false);
    };

    const processing = searchParams.get('processing') ? searchParams.get('processing') && !accountComplete : false;

    const createConnectedAccount = () => {
        setIsSaving(true);
        createBankAccount()
            .then((res) => {
                window.open(res?.data?.link, "_blank")
                setIsSaving(false)
            })
            .catch((err) => {
                console.error(err)
                setIsSaving(false)
            })
    }

    const continueConnectingAccount = (account) => {
        setIsSaving(true);
        removeBankAccount({ account })
            .then(() => createConnectedAccount())
    }

    useEffect(() => {
    }, [account])

    return (
        <Fragment>
            {hasBankAccount ? (
                <Card body id="bank-account-card" className="card-md card--light">
                    <Card.Title as="h5" className="mb-3">
                        Bank Information
                    </Card.Title>
                    {accountComplete &&
                        <>
                            <ul>
                                <li>
                                    <Card.Text>{account?.external_accounts?.data[0]?.bank_name}</Card.Text>
                                </li>
                                <li>
                                    <Card.Text>XXXXX{account?.external_accounts?.data[0]?.last4}</Card.Text>
                                </li>
                                <li>
                                    <Card.Text>U.S. Dollars, United States</Card.Text>
                                </li>
                            </ul>
                            <Stack direction="horizontal" className="mt-3">
                                {/* <Button variant="link" onClick={handleShow}>
                                    Edit
                                </Button> */}
                                <Button variant="link" className="text-danger" onClick={() => removeBank(account)}>
                                    Delete
                                </Button>
                            </Stack>
                        </>
                    }
                    {!accountComplete &&
                        <>
                            <Card.Text>{processing ? 'Verifying account check back in a few minutes' : 'You haven\'t finished setting up your banking info, please continue to finish setting up your bank account'}</Card.Text>
                            <Stack direction="horizontal" className="mt-3">
                                {!processing &&
                                    <>
                                        <Button disabled={isSaving} className="icon-button w-100" size="lg" onClick={() => continueConnectingAccount(account)}>
                                            {isSaving ? (
                                                <Spinner />
                                            ) : (
                                                'Continue'
                                            )}
                                        </Button>
                                    </>
                                }
                            </Stack>
                        </>
                    }
                </Card>
            ) : (
                <LinkBankAccountBtn marginTop="0" createConnectedAccount={createConnectedAccount} isSaving={isSaving} />
            )}

            <BankAccountDetailsModal handleClose={handleClose} show={show} account={account} />
        </Fragment>
    );
}
