import React, { useLayoutEffect, useEffect, useState, useContext } from 'react';

import LoadingContext from '../../context/Loading/Loading';
import { useModals } from '../PurchaseFlow/NewCheckoutWrapper/providers/ModalsProvider';

import { getOrder } from '../../utilities/api';
import { fullHeightContainer, removeFullHeightContainer, userDevice, removeDuplicateTickets } from '../../utilities/helpers';

import { PageLoadingContainer } from '../PageLoadingContainer';
import { BackButton } from '../BackButton';
import { Event } from '../PurchaseFlow/components/Event';
import { MyTickets } from '../MyTickets';
import { MyTicketsSlider } from './MyTicketsSlider';
import { ActionBtns } from '../ActionBtns';
import { TicketModal } from '../TicketModal';

export default function EventDetailsWrapper({ orderId, eventId }) {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { openModal } = useModals()

    const [order, setOrder] = useState()

    const [
        show,
        setShow
    ] = useState(false);

    const [
        ticketAction,
        setTicketAction
    ] = useState('');

    const [
        ticketStatus,
        setTicketStatus
    ] = useState('');

    const [ticket, setTicket] = useState()

    const [deviceType, setDeviceType] = useState("");

    useLayoutEffect(() => {
        const el = document.querySelector('#main-container');

        fullHeightContainer(el);

        return () => {
            removeFullHeightContainer(el);
        };
    }, []);

    useEffect(
        () => {
            showLoading();
            getMyOrders();
            setDeviceType(userDevice)
        },
        [
            orderId
        ]
    );

    const getMyOrders = () => {
        getOrder(orderId).then((res) => {
            let order;
            // remove duplicate tickets in packages
            if (res?.data?.package) {
                const numGeneralAdmisionTickets = res?.data?.tickets?.length / res?.data?.package?.events?.length
                order = {
                    ...res?.data,
                    tickets: res?.data?.tickets[0]?.generalAdmission ? res?.data?.tickets?.slice(0, numGeneralAdmisionTickets) : removeDuplicateTickets(res?.data?.tickets)
                }
            } else {
                order = res?.data
            }
            setOrder(order)
            hideLoading()
        }).catch((err) => {
            console.error(err)
            hideLoading()
        });
    }

    // create function that is a promise so button spinner works 
    const getOrders = () => {
        return new Promise((resolve, reject) => {
            getMyOrders()
            resolve()
        }).catch((err) => {
            console.error(err)
            reject()
        });
    }

    const handleShow = () => setShow(true);

    const handleClick = (action, ticket) => {
        handleShow();
        setTicketAction(action);
        setTicket(ticket)
    };

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <section className="spacer-xs full-height-wrapper background-gradient">
                    <div className="section-heading-sm">
                        <h1>{deviceType && deviceType === 'Mobile' ? 'Tickets' : 'Event details'}</h1>
                        <div className="tablet-desktop-only">
                            <BackButton />
                        </div>
                    </div>
                    {deviceType && deviceType === 'Mobile' && (
                        <>
                            <MyTicketsSlider order={order} event={order?.package?.events?.find(event => event?.uuid === eventId)} handleClick={handleClick} />
                            <ActionBtns handleClick={handleClick} ticketStatus={ticketStatus} order={order} />
                        </>
                    )}
                    {deviceType && deviceType === 'Desktop' && (
                        <>
                            <Event event={order?.event || order?.package?.events?.find(event => event?.uuid === eventId)} openModal={openModal} />
                            <MyTickets order={order} handleClick={handleClick} ticketStatus={ticketStatus} />
                        </>
                    )}
                    <TicketModal
                        ticketAction={ticketAction}
                        setTicketStatus={setTicketStatus}
                        show={show}
                        setShow={setShow}
                        ticket={ticket}
                        event={order?.package?.events?.find(event => event?.uuid === eventId)}
                        order={order}
                        getMyOrders={getOrders}
                    />
                </section>
            )}
        </>
    );
}
