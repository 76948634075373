import React, {
    Suspense,
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
    lazy,
} from "react";
import { CenterBottomModal } from "../../../components/CenterBottomModal";
import { Spinner } from "../../../../LoadingContainer/Spinner";
import { Stack } from "react-bootstrap";
import useSeatmapStore from "../../../../../stores/seatmapStore";
import useFiltersStore from "../../../../../stores/filtersStore";
import { useTickets } from "../TicketsProvider/TicketsProvider";

// Lazyload all these components since there is a high change most of them are never opened
const LazyTicketInfo = lazy(() =>
    import("../../../components/TicketInfo/TicketInfo")
);
const LazyViewSwiper = lazy(() =>
    import("../../../components/ViewSwiper/ViewSwiper")
);
const LazyQuantityFilter = lazy(() =>
    import("../../../components/QuantityFilter/QuantityFilter")
);
const LazyPriceFilterCard = lazy(() =>
    import("../../../components/PriceFilterCard/PriceFilterCard")
);
const LazyPriceSort = lazy(() =>
    import("../../../components/PriceSort/PriceSort")
);
const LazyEventInformation = lazy(() =>
    import("../../../components/EventInformation/EventInformation")
);

// Create the context
const ModalsContext = createContext(undefined);

export const useModals = () => {
    const context = useContext(ModalsContext);
    if (!context) {
        throw new Error("Context must be used within a ModalsProvider");
    }
    return context;
};

// Provide certain type of modals used in the page
export const ModalsProvider = ({ children }) => {
    // Zustand
    const eventTicketLimit = useFiltersStore(state => state.eventTicketLimit);
    const offers = useFiltersStore(state => state.offers);
    const {updateSearchParam} = useTickets();

    const dialogRef = useRef(null)
    const [viewingSection, setViewingSection] = useState(false);
    const [sectionNumber, setSectionNumber] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [modalsData, setModalsData] = useState(null);
    const [CDBBlurred, setCDBBlurred] = useState(false);
    const [isPackage, setIsPackage] = useState(false)

    // Modal has different bg color + mobile padding
    // In these 2 cases
    useEffect(() => {
        const shouldBlur = [
            "Filter by section",
            "Information",
        ].includes(modalType);
        setCDBBlurred(shouldBlur);
    }, [modalType]);

    const openCBModal = (type, data, isPackage) => {
        console.log("openCBMModal", type, data, isPackage);
        setModalType(type);
        setModalsData(data)
        setIsPackage(isPackage)
        dialogRef.current.showModal();
    };

    const closeCBModal = () => {
        setModalType(null);
        setModalsData(null)
        setIsPackage(false)
        dialogRef.current.close();
    };

    const closeModalUpdateSearch = (param, value) => {
        updateSearchParam(param, value);
        closeCBModal();
    }

    const modalComponents = {
        "Ticket information": () => <LazyTicketInfo />,
        "Your view": () => <LazyViewSwiper viewingSectionId={viewingSection} sectionNumber={sectionNumber} imageHeight={350} />,
        "Ticket Quantity": () => (
            <LazyQuantityFilter
                range={{ min: 1, max: eventTicketLimit ? eventTicketLimit : 50 }}
                style='actions-bar--vertical greyScale'
                btnStyle='btn-toggle--no-borders'
                handleChange={(val) => closeModalUpdateSearch("quantity", val)}
                dropdown={false}
            />
        ),
        "Price Range": () => <LazyPriceFilterCard closeModal={closeCBModal} />,
        "Sort by": () => (
            <LazyPriceSort
                style='actions-bar--vertical greyScale'
                btnStyle='btn-toggle--no-borders'
                handleChange={(val) => closeModalUpdateSearch("sort", val)}
                dropdown={false}
            />
        ),
        "Information": () => <LazyEventInformation obj={modalsData} isPackage={isPackage} />,
    };

    // Get the component based on the current modal type
    const ModalContent = modalType ? modalComponents[modalType] : null;

    return (
        <ModalsContext.Provider
            value={{
                openCBModal,
                setViewingSection,
                setSectionNumber,
                offers,
                closeCBModal,
            }}
        >
            <CenterBottomModal
                dialogRef={dialogRef}
                handleClose={closeCBModal}
                title={
                    modalType === 'Information'
                        ? `${isPackage ? 'Package' : 'Event'} ${modalType}`
                        : modalType
                }
                blurred={CDBBlurred}
            >
                <Suspense
                    fallback={
                        <Stack className='align-items-center'>
                            <Spinner variant='primary' size='lg' />
                        </Stack>
                    }
                >
                    {ModalContent && <ModalContent />}
                </Suspense>
            </CenterBottomModal>
            {children}
        </ModalsContext.Provider>
    );
};
