import React from 'react';

import Alert from 'react-bootstrap/Alert';
import Stack from 'react-bootstrap/Stack';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { ActionBtns } from '../ActionBtns';

import scanQR from '../../assets/icons/scan-qr-code.svg';

export default function MyTickets({ order, guestList, handleClick, ticketStatus }) {
    const tickets = order ? order?.tickets : guestList?.guest_passes;

    const getTitle = () => {
        let title;
        if (order) {
            title = `${tickets?.length > 1 ? 'Tickets' : 'Ticket'}`
        }

        else if (guestList) {
            title = `Guest ${tickets?.length > 1 ? 'passes' : 'pass'}`
        }

        return title;
    }

    return (
        <section>
            <div className="section-heading-md heading--flex">
                <h2>{getTitle()} ({tickets.length})</h2>
                <ActionBtns handleClick={handleClick} ticketStatus={ticketStatus} order={order} />
            </div>
            <Alert variant="info">
                <img src={scanQR} width="44" height="44" alt="" />
                <div>
                    <p className="fw-semi-bold">Your phone is your ticket</p>
                    <p className="caption">
                        Login to blocktickets.xyz on your phone and go to My events and then click
                        Event details.
                    </p>
                </div>
            </Alert>
            <Stack gap={3} className="mt-4">
                {tickets.map((ticket, index) => (
                    <Card body className="card--white" key={index}>
                        <div className="heading--flex">
                            <Card.Title as="h5" className='card-title-sm'>
                                {ticket?.generalAdmission ? (
                                    <>General Admission</>
                                ) : (
                                    <Stack direction='horizontal' gap={4}>
                                        <Stack direction='horizontal' gap={2}>
                                            <span className="text-muted normal fw-normal">Sec</span>
                                            <span>{ticket?.sectionNumber}</span>
                                        </Stack>
                                        <Stack direction='horizontal' gap={2}>
                                            <span className="text-muted normal fw-normal">Row</span>
                                            <span>{ticket?.rowNumber}</span>
                                        </Stack>
                                        <Stack direction='horizontal' gap={2}>
                                            <span className="text-muted normal fw-normal">Seat</span>
                                            <span>{ticket?.seatNumber}</span>
                                        </Stack>
                                    </Stack>
                                )}
                            </Card.Title>
                            <Button onClick={() => handleClick('details', ticket)} variant="link">Details</Button>
                        </div>
                    </Card>
                ))}
            </Stack>
        </section>
    );
}
