import React from "react";

import { PackageSeatmapProvider } from "./PackageSeatmapProvider";

import { PackageDetails } from "./PackageDetails";

import "./viewPackageWrapper.scss";

export default function ViewPackageWrapper({ eventPackage, purchaseLog }) {
    console.log("eventPackage: ", eventPackage)
    return (
        <PackageSeatmapProvider eventPackage={eventPackage} purchaseLog={purchaseLog}>
            <PackageDetails eventPackage={eventPackage} />
        </PackageSeatmapProvider>
    );
}
