import { ModalTop } from "../../../../components/ModalTop";
import { Suspense, useEffect } from "react";
import "./seatmapModal.scss";
import useSeatmapStore from "../../../../../../stores/seatmapStore";

export default function SeatmapModal({
  dialogRef,
  hideSeatmap,
  title,
  dialogOpen,
  SeatmapWrapper // This can change
}) {
  const resetMapState = useSeatmapStore(state => state.resetMapState);

  useEffect(() => {
    if (dialogOpen) {
      resetMapState();
    }
  }, [dialogOpen]);

  return (
    <dialog ref={dialogRef} className={`dialog-sm-base`}>
      <div className='dialog-sm-inner'>
        <ModalTop
          title={title}
          handleClose={hideSeatmap}
          isSeatmapModal={true}
        />
        <div className='sm-content'>
          <Suspense fallback={null}>
            {dialogOpen && (
              <SeatmapWrapper
                handleClose={hideSeatmap}
              />
            )}
          </Suspense>
        </div>
      </div>
    </dialog>
  );
}
