import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { formatDateTime, formatCurrency, getTimezoneDate, timezones, getSinglarOrPluralWord } from '../../../../../utilities/helpers';

export default function InvoiceRow({ invoice }) {

    // event or package
    const obj = invoice?.event || invoice?.package

    const [ticketCount, setTicketCount] = useState(0);

    useEffect(() => {
        // if (invoice?.details?.listing) {
        //     setTicketCount(invoice?.details?.listing?.quantity)
        // } else if (invoice?.details?.details) {
        //     setTicketCount(invoice.status == "completeFromTransfer" ? invoice?.tickets.length : invoice?.details?.details?.ticketCount)
        // } else if (invoice?.details) {
        //     setTicketCount(invoice.status == "completeFromTransfer" ? invoice?.tickets.length : invoice?.tickets?.length)

        // }
        setTicketCount(invoice?.tickets?.length)
    }, [])

    return (
        <tr>
            <th scope="row">{formatDateTime(getTimezoneDate(invoice?.createdAt, invoice?.timezone))}</th>
            <td>{obj?.name}</td>
            <td>{ticketCount} {getSinglarOrPluralWord(ticketCount)}</td>
            <td>{formatCurrency(invoice?.total)}</td>
            <td>
                <Link to={`invoice/${invoice.id}`}>Download</Link>
            </td>
        </tr>
    );
}
