import { ModalsProvider } from "./providers/ModalsProvider";
import { NewCheckoutContainer } from "./NewCheckoutContainer";
import { TimeoutProvider } from "./providers/TimeoutProvider/TimeoutProvider";

export default function NewCheckoutWrapper() {
    return (
        <TimeoutProvider>
            <ModalsProvider>
                <NewCheckoutContainer />
            </ModalsProvider>
        </TimeoutProvider>
    );
}
